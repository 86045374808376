import gql from "graphql-tag";
const GET_SUB_CONCEPTS_MUTATION = gql`
  mutation(
    $statisticOperation: String
    $conceptType: String
    $returnSize: Int
    $newspaper: String
    $channel: String
    $startDate: String
    $endDate: String
    $parent: String
  ) {
    getStatistic(
      statisticOperation: $statisticOperation
      conceptType: $conceptType
      returnSize: $returnSize
      newspaper: $newspaper
      channel: $channel
      startDate: $startDate
      endDate: $endDate
      parent: $parent
    ) {
      result {
        title
        type
        uuid
        frequency
      }
    }
  }
`;

export default GET_SUB_CONCEPTS_MUTATION;
