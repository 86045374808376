import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  Box,
  TextField,
  Autocomplete,
  InputAdornment,
  Tooltip,
  Chip,
  Paper,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";

import SEARCH_CONCEPTS from "../../../mutations/searchConcepts";

interface IProps {
  tags: any;
  setTags: any;
}
const TagBoxComponent = (props: IProps) => {
  const { tags, setTags } = props;
  const [searchString, setSearchString] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const [suggestions, setSuggestions] = useState<any>([]);
  const [searchTagTimeout, setSearchTagTimeout] = useState<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);

  const [searchConcepts] = useMutation(SEARCH_CONCEPTS);

  useEffect(() => {
    clearTimeout(searchTagTimeout);
    if (!searchString || searchString.length < 2) {
      setSuggestions([]);
    } else {
      setSearchTagTimeout(
        setTimeout(() => {
          clearTimeout(searchTagTimeout);
          searchConcepts({
            variables: {
              title: searchString,
              type: "all",
              draft: false,
              size: 7,
            },
          })
            .then((data: any) => {
              data?.data?.searchConcepts?.result &&
                setSuggestions(data.data.searchConcepts.result);
            })
            .catch((err: Error) => {
              setSuggestions([]);
            });
        }, 500)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  return (
    <Box sx={{ position: "relative" }}>
      <Autocomplete
        value={tags ?? []}
        fullWidth
        multiple
        limitTags={5}
        freeSolo
        autoComplete={false}
        open={false}
        options={[]}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        inputValue={searchString}
        renderInput={(params) => (
          <TextField
            placeholder="Search for tags"
            style={{
              display: "inline-block",
              whiteSpace: "nowrap",
            }}
            {...params}
            variant="outlined"
            size="small"
            label="Active tags"
            name="search-tags"
            autoComplete="off"
            onChange={(e: any) => {
              setSearchString(e.target.value);
            }}
            onKeyDown={(e: any) => {
              if (e.key === "Backspace") {
                e.stopPropagation();
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: <InputAdornment position="end"></InputAdornment>,
            }}
          />
        )}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: any, index: number) => (
            <Tooltip
              key={index}
              arrow
              title={<Box fontSize="1.1em">{option.title}</Box>}
            >
              <Chip
                size="small"
                variant="outlined"
                label={option.title}
                {...getTagProps({ index })}
                onDelete={() => {
                  setTags(tags.filter((tag: any) => tag.uuid !== option.uuid));
                }}
              />
            </Tooltip>
          ))
        }
      />
      {isFocused && suggestions?.length > 0 && (
        <Paper sx={{ position: "absolute", width: "100%", zIndex: 2 }}>
          <List dense disablePadding>
            {suggestions.map((suggestion: any, i: number) => (
              <ListItem
                key={i}
                disableGutters
                disablePadding
                onMouseDown={() => {
                  if (!tags?.find((tag: any) => tag.uuid === suggestion.uuid)) {
                    setTags([
                      ...tags,
                      {
                        uuid: suggestion.uuid,
                        title: suggestion.title,
                        type: suggestion.type,
                      },
                    ]);
                    setSearchString("");
                    setSuggestions([]);
                  }
                }}
              >
                <ListItemButton>
                  <Box
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <b>{suggestion.title}</b>

                    {suggestion?.shortDescription && (
                      <Typography component="span" variant="body2">
                        {" - "}
                        {suggestion.shortDescription}
                      </Typography>
                    )}
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </Box>
  );
};

export default TagBoxComponent;
