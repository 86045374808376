import { Box, Typography, Slider } from "@mui/material";

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
];

const valuetext = (value: any) => {
  return `${value}`;
};

const DoubleSliderInput = (props: any) => {
  const {
    doubleValue,
    label,
    min,
    max,
    step,
    disabled,
    onChange,
  } = props;

  return (
    <Box width="100%">
      <Typography id="discrete-slider-custom" gutterBottom>
        <Box component="span" fontSize="0.77rem" fontWeight="900">
          {label}
        </Box>
      </Typography>
      <Slider
        value={doubleValue}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-small-steps"
        step={step}
        marks={marks}
        min={min}
        max={max}
        valueLabelDisplay="auto"
        onChange={onChange}
        disabled={disabled}
      />
    </Box>
  );
};

export default DoubleSliderInput;
