import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { CTX } from "../../../utils/ContextStore";
import { CTX as tagCTX } from "../TagContext";
import { Button } from "@mui/material";
import { Clear } from "@mui/icons-material";

import TAG_QUEUE_ARTICLE from "../../../mutations/tagQueueArticle";

interface Props {
  disabled?: boolean;
  clearAll(): void;
}

const SkipButton = (props: Props) => {
  const { disabled, clearAll } = props;
  const [tagQueueArticle] = useMutation(TAG_QUEUE_ARTICLE);
  const { username }: any = useContext(CTX);
  const { setHasQueueArticle, updateQueueStatistics }: any = useContext(tagCTX);

  return (
    <Button
      disabled={disabled}
      variant="outlined"
      color="primary"
      className="mr-2"
      onClick={() => {
        tagQueueArticle({
          variables: {
            username: username,
            finished: true,
            tags: [],
          },
        }).then(() => {
          setHasQueueArticle(false);
          updateQueueStatistics();
          clearAll();
        });
      }}
    >
      <Clear fontSize="small" className="mr-1" />
      Skip
    </Button>
  );
};

export default SkipButton;
