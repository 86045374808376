import { Fragment, useState, useEffect, useContext } from "react";
import "./AutoTaggingDemo.css";
import { Authenticator } from "../../utils/Authenticator";
import { PRIVILEGES } from "../PrivateRoute/PrivateRoute";
import { useMutation } from "@apollo/client";
import {
  Card,
  Badge,
  OverlayTrigger,
  Table,
  Popover,
  Modal,
} from "react-bootstrap";
import {
  Box,
  Grid,
  Button,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Tooltip,
  Alert,
  CircularProgress,
  LinearProgress,
  Chip,
  Paper,
} from "@mui/material";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  Description,
  Grain,
  PersonOutlineOutlined,
  AccountBalance,
  EventNote,
  LocationOn,
  LocalOfferOutlined,
  ExpandMore,
  ExpandLess,
  ChevronRight,
  KeyboardReturn,
  Check,
  DeleteOutlined,
  Clear,
  Search,
  NavigateNext,
} from "@mui/icons-material";
import { TiThLargeOutline } from "react-icons/ti";
import { FaWikipediaW, FaDatabase, FaMapMarkedAlt } from "react-icons/fa";

import { CTX } from "../../utils/ContextStore";
import { CTX as tagCTX } from "./TagContext";
import CMConceptModal from "../ConceptManagement/CMConceptModal";
import SEARCH_ARTICLES from "../../mutations/searchArticlesMutation";
import GET_QUEUE_ARTICLE from "../../mutations/queueArticle";
import TAG_QUEUE_ARTICLE from "../../mutations/tagQueueArticle";
import GET_CONCEPT from "../../mutations/getConcept";
import Editor from "./Editor";
import DatasetPicker from "../PanelComponents/DatasetPicker";
import TagInfo from "./TagInfo";
import CMMap from "../Map/CMMap";
import SearchTagsComponent from "./SearchTagsComponent";
import GenderDisplayer from "./GenderDisplayer";
import ImageDisplayer from "./ImageDisplayer";
import QueueStatisticsDisplayer from "./TagQualityAssurance/QueueStatisticsDisplayer";
import SkipButton from "./TagQualityAssurance/SkipButton";
import PlayDemoButton from "./PlayDemoButton";
import LimitSelector from "./LimitSelector";
import AdvancedSettingComponent from "./AdvancedSettingComponent";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    maxHeight: "400px",
  },
});

interface Props {
  tagQualityAssurance?: boolean;
}

const AutotaggingDemo = (props: Props) => {
  const { tagQualityAssurance } = props;
  const titleIconSize = "1.2em";
  const iconSize = "1.2em";
  const entityButtonIconSize = "0.75em";
  const entityButtonStyle = {
    color: "rgb(55, 55, 55)",
    backgroundColor: "#fff",
    border: "0.2px solid rgba(0,0,0,0.125)",
  };

  const [searchShow, setSearchShow] = useState<boolean>(false);
  const [isLoadingNextArticle, setIsLoadingNextArticle] =
    useState<boolean>(false);
  const [isLoadingBroader, setIsLoadingBroader] = useState<boolean>(false);

  const [searchArticleString, setSearchArticleString] = useState<string>("");

  const [chosenConcept, setChosenConcept] = useState<any>({});
  const [addedConcept, setAddedConcept] = useState<any>(undefined);
  const [modifiedConcept, setModifiedConcept] = useState<any>(undefined);
  const [deletedConcept, setDeletedConcept] = useState<any>(undefined);

  const [getConcept] = useMutation(GET_CONCEPT);
  const [getQueueArticle] = useMutation(GET_QUEUE_ARTICLE);
  const [tagQueueArticle] = useMutation(TAG_QUEUE_ARTICLE);

  const newEntity = {
    uuid: "",
    title: "",
    type: "person",
    shortDescription: "",
    longDescription: "",
    aliases: [],
    broader: "",
  };

  const newTopic = {
    uuid: "",
    title: "",
    type: "topic",
    shortDescription: "",
    longDescription: "",
    broader: "",
  };

  const slideSpeed = 400;

  const { username, activeEndpoint, prevEndpoint, showImageDisplayer }: any =
    useContext(CTX);
  const {
    categories,
    setCategories,
    topics,
    setTopics,
    entities,

    broaderConcepts,
    broaderConceptsFallback,
    setBroaderConceptsFallback,

    setEntities,
    setEditorText,
    textEditor,

    tagError,
    setTagError,

    tagSettings,
    setTagSettings,

    formatTag,
    clearTags,
    setTagQualityAssurance,
    isPlaying,
    setIsPlaying,
    setForceTagging,
    taggingTimeouts,
    clearTimeouts,

    hideCategories,
    setHideCategories,
    hideTopics,
    setHideTopics,
    hideEntities,
    setHideEntities,

    addConcept,
    deleteCategory,
    deleteTopic,
    deleteEntity,

    editConceptModalShow,
    setEditConceptModalShow,

    createTopicModalShow,
    setCreateTopicModalShow,
    createConceptModalShow,
    setCreateConceptModalShow,

    updateQueueStatistics,
    hasQueueArticle,
    setHasQueueArticle,

    selectedDemoArticle,
  }: any = useContext(tagCTX);

  useEffect(() => {
    setTagQualityAssurance(tagQualityAssurance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //##### TRIGGER RETAG AFTER SWITCHING ENDPOINT #####
    if (activeEndpoint?.url === prevEndpoint?.url) {
      return;
    }
    if (textEditor?.getData) {
      textEditor.setData(textEditor.getData());
    }
    if (tagQualityAssurance) {
      updateQueueStatistics();
      setEditorText("");
      clearTags();
      setHasQueueArticle(false);
      setIsLoadingNextArticle(false);
    }
    if (tagError.error) {
      setTagError({
        error: false,
        message: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEndpoint]);

  useEffect(() => {
    //##### TRIGGER RETAG AFTER CHANGING TAG SETTINGS #####
    textEditor?.getData && textEditor.setData(textEditor.getData());
    if (tagError.error) {
      setTagError({
        error: false,
        message: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagSettings]);

  useEffect(() => {
    hideCategories
      ? $("#categories").slideUp(slideSpeed)
      : $("#categories").slideDown(slideSpeed);
    hideTopics
      ? $("#topics").slideUp(slideSpeed)
      : $("#topics").slideDown(slideSpeed);
    hideEntities
      ? $("#entities").slideUp(slideSpeed)
      : $("#entities").slideDown(slideSpeed);
  }, [hideCategories, hideTopics, hideEntities]);

  useEffect(() => {
    tagQualityAssurance
      ? $(".button-panel").css("background", "rgba(89, 107, 191, 0.1)")
      : $(".button-panel").css("background", "rgb(245, 245, 245)");
  }, [tagQualityAssurance]);

  // Triggers when Play Demo is pressed
  useEffect(() => {
    const demoTitle: string = selectedDemoArticle?.headline || "";
    const demoText: string[] = selectedDemoArticle?.body || [];
    if (isPlaying) {
      clearTags();
      if (textEditor) {
        textEditor.editing.view.document.isReadOnly = true;
        textEditor.editing.view.document.isFocused = true;
      }
      let i = 0;
      let j = 0;
      let textIndex = 0;
      let textInterval: any;
      let titleInterval: any;
      let intervalTimeout: any;
      clearTimeouts(taggingTimeouts);
      titleInterval = setInterval(function () {
        if (demoText)
          if (i === 0) textEditor.setData("<h3>" + demoTitle[i] + "</h3>");
          else if (i === demoTitle.length) {
            //last iteration of title
            setForceTagging(true);
            textEditor.setData(textEditor.getData());
            setEditorText(textEditor.getData());
            setForceTagging(false);
            clearInterval(titleInterval);

            //########### LOOP THE DEMO TEXT #############
            intervalTimeout = setTimeout(() => {
              textInterval = setInterval(() => {
                if (j === 0) {
                  textEditor.setData(
                    textEditor.getData() +
                      "<p>" +
                      demoText[textIndex][j] +
                      "</p>"
                  );
                  j++;
                } else if (j === demoText[textIndex].length) {
                  //last iteration
                  $("#editor .ck-editor__editable p")
                    .last()
                    .append(demoText[textIndex][j]);
                  setForceTagging(true);
                  const tagText = textEditor.getData();
                  setEditorText(tagText);
                  textEditor.setData(tagText);
                  setForceTagging(false);
                  textIndex += 1;
                  j = 0;
                  if (!demoText[textIndex]) {
                    if (textEditor) {
                      textEditor.editing.view.document.isReadOnly = false;
                      textEditor.editing.view.document.isFocused = true;
                      textEditor.model.change((writer: any) => {
                        writer.setSelection(
                          writer.createPositionAt(
                            textEditor.model.document.getRoot(),
                            "end"
                          )
                        );
                      });
                    }
                    setIsPlaying(false);
                    clearInterval(textInterval);
                  }
                } else {
                  $("#editor .ck-editor__editable p")
                    .last()
                    .append(demoText[textIndex][j]);
                  j++;
                }
              }, 20);
            }, 800);
          } else {
            $("#editor .ck-editor__editable h3").eq(0).append(demoTitle[i]);
          }
        i++;
      }, 30);
      return () => {
        clearInterval(titleInterval);
        clearInterval(textInterval);
        clearTimeout(intervalTimeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  const stopDemo = () => {
    clearTimeouts(taggingTimeouts);
    setIsPlaying(false);
    setEditorText("");
    textEditor.setData("");
    clearTags();
    setForceTagging(false);
    if (textEditor) {
      textEditor.editing.view.document.isReadOnly = false;
      textEditor.editing.view.document.isFocused = true;
    }
  };

  const clearAll = () => {
    stopDemo();
    if (textEditor) {
      textEditor.editing.view.document.isReadOnly = false;
      textEditor.editing.view.document.isFocused = true;
    }
  };

  const [searchArticles] = useMutation(SEARCH_ARTICLES);
  const [retrievedArticles, setRetrievedArticles] = useState([]);
  const [isLoadingArticles, setIsLoadingArticles] = useState(false);
  const [submittedSearchString, setSubmittedSearchString] = useState("");

  const searchArticlesInput = (
    <form
      autoComplete="off"
      onSubmit={(e: any) => {
        e.preventDefault();
        setSubmittedSearchString("");
        if (!searchArticleString) {
          return;
        }
        setIsLoadingArticles(true);
        searchArticles({
          variables: {
            searchString: searchArticleString,
            returnSize: 20,
          },
        })
          .then((data: any) => {
            data?.data?.searchArticlesMutation &&
              setRetrievedArticles(data.data.searchArticlesMutation);
          })
          .catch((err: any) => {
            setRetrievedArticles([]);
          })
          .finally(() => {
            setSubmittedSearchString(searchArticleString);
            setIsLoadingArticles(false);
          });
      }}
      style={{ display: "inline-block", width: "45%", padding: 16 }}
    >
      <TextField
        autoComplete="off"
        autoFocus
        fullWidth
        value={searchArticleString}
        label="Search article"
        type="text"
        variant="filled"
        size="small"
        InputLabelProps={{
          shrink: true,
          sx: { fontWeight: 700 },
        }}
        onChange={(e) => setSearchArticleString(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Search articles" placement="top">
                <IconButton type="submit" edge="end">
                  <Search />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );

  const applyBroaderConceptsFallback = async (concept: any) => {
    let res: any[] = broaderConceptsFallback;
    let currentConcept: any = concept;
    if (
      !currentConcept
      //|| currentConcept?.uuid === currentConcept?.broader
    ) {
      return res;
    }
    const index = broaderConceptsFallback.findIndex(
      (broader: any) => broader?.uuid === currentConcept?.broader
    );
    if (index === -1) {
      while (currentConcept?.broader) {
        setIsLoadingBroader(true);
        let data: any = await getConcept({
          variables: { uuid: currentConcept.broader },
        }).catch(() => {});
        let broaderConcept: any = null;
        if (
          data?.data?.getConcept?.error !== true &&
          data?.data?.getConcept?.result?.length > 0
        ) {
          broaderConcept = data.data.getConcept.result[0];
          broaderConcept.childConcepts = [currentConcept.uuid];
          res.push(broaderConcept);
        }
        /*else {
          res.push({
            title: currentConcept.broader,
            uuid: currentConcept.broader,
            broader: "",
            childConcepts: [currentConcept.uuid],
            invalid: true,
          });
        }*/
        if (currentConcept?.uuid === broaderConcept?.uuid) {
          broaderConcept = null;
        }
        currentConcept = broaderConcept;
      }
    } else {
      //Store broaders in client
      !res[index].childConcepts.includes(concept.uuid) &&
        res[index].childConcepts.push(concept.uuid);
    }
    !broaderConceptsFallback.some(
      (broader: any) => broader.uuid === concept.broader
    ) && setBroaderConceptsFallback(res);
    setIsLoadingBroader(false);
    return res;
  };

  const getBroaderConcepts = (uuid: string) => {
    let res: any[] = [];
    let broaderArray = broaderConcepts || broaderConceptsFallback;
    if (broaderArray || broaderArray.length > 0) {
      let broaderIndex = broaderArray.findIndex((broader: any) => {
        if (!broader?.childConcepts) {
          broader.childConcepts = [];
        }
        return broader.childConcepts.includes(uuid);
      });
      while (broaderIndex !== -1) {
        const broaderConcept = broaderArray[broaderIndex];
        res.push(broaderConcept);
        broaderIndex = broaderArray.findIndex((broader: any) => {
          if (!broader?.childConcepts) {
            broader.childConcepts = [];
          }
          return broader.childConcepts.includes(broaderConcept.uuid);
        });
        if (uuid && broaderConcept?.uuid === uuid) {
          break;
        }
      }
    }
    return res;
  };

  const [hoveredBroader, setHoveredBroader] = useState<any[]>([]);

  const TagDisplayer = () => (
    <>
      <Paper sx={{ mb: 2, width: "100%", zIndex: 5 }}>
        <Card.Header className="tag-header">
          <Card.Title>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Description className="mr-2" />
                Categories {categories.length > 0 && `(${categories.length})`}
              </Box>
              <Box display="flex" alignItems="center">
                <LimitSelector
                  value={
                    tagSettings.categories === -1 ||
                    !Number.isInteger(tagSettings.categories)
                      ? ""
                      : tagSettings.categories
                  }
                  onChange={(e: any) => {
                    setTagSettings({
                      ...tagSettings,
                      categories: parseInt(e.target.value),
                    });
                  }}
                  options={[
                    { label: "Default", value: -1 },
                    { label: "0", value: 0 },
                    { label: "1", value: 1 },
                    { label: "2", value: 2 },
                    { label: "3", value: 3 },
                    { label: "4", value: 4 },
                    { label: "5", value: 5 },
                  ]}
                />

                {hideCategories ? (
                  <Tooltip title="Expand" placement="top">
                    <IconButton
                      size="small"
                      className="mr-2 float-right"
                      onClick={() => {
                        setHideCategories(false);
                      }}
                    >
                      <ExpandMore />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Collapse" placement="top">
                    <IconButton
                      size="small"
                      className="mr-2 float-right"
                      onClick={() => {
                        setHideCategories(true);
                      }}
                    >
                      <ExpandLess />
                    </IconButton>
                  </Tooltip>
                )}
                <TagInfo type="category" />
              </Box>
            </Box>
          </Card.Title>
        </Card.Header>
        <Card.Body id="categories" className="tag-card-body tag-box">
          <Card.Text as="div">
            {categories.map((item: any) => (
              <Badge
                key={item.uuid}
                className={
                  item.weight >= 0.9
                    ? "tag-badge green-tag w3-center w3-animate-zoom"
                    : item.weight < 0.9 && item.weight >= 0.8
                    ? "tag-badge yellowgreen-tag w3-center w3-animate-zoom"
                    : item.weight < 0.8 && item.weight >= 0.7
                    ? "tag-badge yellow-tag w3-center w3-animate-zoom"
                    : item.weight < 0.7 && item.weight >= 0.6
                    ? "tag-badge orange-tag w3-center w3-animate-zoom"
                    : "tag-badge red-tag w3-center w3-animate-zoom"
                }
              >
                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  popperConfig={
                    {
                      /*
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: { enabled: false },
                    */
                    }
                  }
                  onEnter={async () => {
                    setHoveredBroader([]);
                    if (!broaderConcepts && item.broader) {
                      await applyBroaderConceptsFallback(item);
                    }
                    setHoveredBroader(getBroaderConcepts(item.uuid));
                  }}
                  overlay={
                    <Popover id={item.title} className="tag-popover">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography component="div">
                          <Box fontWeight="700" mr={4}>
                            {item.title}
                          </Box>
                        </Typography>
                        <Typography
                          className={
                            item.weight >= 0.9
                              ? "green-bottom"
                              : item.weight < 0.9 && item.weight >= 0.8
                              ? "yellowgreen-bottom"
                              : item.weight < 0.8 && item.weight >= 0.7
                              ? "yellow-bottom"
                              : item.weight < 0.7 && item.weight >= 0.6
                              ? "orange-bottom"
                              : "red-bottom"
                          }
                        >
                          ({(100 * item.weight).toFixed(0)}%)
                        </Typography>
                      </Box>

                      {isLoadingBroader ? (
                        <CircularProgress size="1em" />
                      ) : null}

                      {hoveredBroader.length > 0 && (
                        <Box mb={0.4}>
                          {hoveredBroader.map((broader: any, i: number) => {
                            return i === 0 ? (
                              <Typography
                                key={i}
                                style={{ fontWeight: 600 }}
                                variant="inherit"
                                component="a"
                              >
                                <KeyboardReturn
                                  style={{
                                    transform: "rotateY(180deg)",
                                  }}
                                  fontSize="small"
                                />{" "}
                                {broader.title}
                              </Typography>
                            ) : (
                              <Typography
                                style={{ fontWeight: 600 }}
                                variant="inherit"
                                component="a"
                                key={i}
                              >
                                <ChevronRight fontSize="small" />{" "}
                                {broader.title}
                              </Typography>
                            );
                          })}
                        </Box>
                      )}
                      {item.shortDescription && (
                        <Typography component="div" variant="subtitle2">
                          <Box mt={0.4}>{item.shortDescription}</Box>
                        </Typography>
                      )}
                      {item.aliases?.length > 0 && (
                        <Box mt={1}>
                          <Typography
                            variant="caption"
                            style={{ color: "#777" }}
                          >
                            <span className="font-weight-bold">Aliases: </span>
                            {item.aliases.map(
                              (alias: String, index: Number) => {
                                return index !== 0 ? ", " + alias : alias;
                              }
                            )}
                          </Typography>
                        </Box>
                      )}
                      {item.source && (
                        <Typography variant="caption" style={{ color: "#777" }}>
                          <Box fontWeight={500} mt={1}>
                            Source: {item.source}
                          </Box>
                        </Typography>
                      )}
                    </Popover>
                  }
                >
                  <span className="tag-name d-flex align-items-center">
                    <span
                      className="interactive d-flex align-items-center"
                      style={{ flexGrow: 1 }}
                      onClick={() => {
                        setChosenConcept(item);
                        setEditConceptModalShow(true);
                      }}
                    >
                      <LocalOfferOutlined fontSize="small" className="m-1" />
                      {item.title}
                    </span>

                    <IconButton
                      size="small"
                      className="xButton"
                      onClick={(e: any) => {
                        deleteCategory(item);
                        if (tagQualityAssurance)
                          tagQueueArticle({
                            variables: {
                              username: username,
                              finished: false,
                              tags: [
                                ...categories.filter((tag: any) => {
                                  return tag !== item;
                                }),
                                ...topics,
                                ...entities,
                              ],
                            },
                          }).catch((err) => {});
                      }}
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  </span>
                </OverlayTrigger>
              </Badge>
            ))}
          </Card.Text>
          <SearchTagsComponent
            type="category"
            tagQualityAssurance={tagQualityAssurance}
          />
        </Card.Body>
      </Paper>

      <Paper sx={{ mb: 2, width: "100%", zIndex: 4 }}>
        <Card.Header className="tag-header">
          <Card.Title>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Grain className="mr-2" />
                Topics {topics.length > 0 && `(${topics.length})`}
              </Box>
              <Box display="flex" alignItems="center">
                <LimitSelector
                  value={
                    tagSettings.topics === -1 ||
                    !Number.isInteger(tagSettings.topics)
                      ? ""
                      : tagSettings.topics
                  }
                  onChange={(e: any) => {
                    setTagSettings({
                      ...tagSettings,
                      topics: parseInt(e.target.value),
                    });
                  }}
                  options={[
                    { label: "Default", value: -1 },
                    { label: "0", value: 0 },
                    { label: "1", value: 1 },
                    { label: "2", value: 2 },
                    { label: "3", value: 3 },
                    { label: "4", value: 4 },
                    { label: "5", value: 5 },
                    { label: "6", value: 6 },
                    { label: "7", value: 7 },
                    { label: "8", value: 8 },
                    { label: "9", value: 9 },
                    { label: "10", value: 10 },
                  ]}
                />
                {hideTopics ? (
                  <Tooltip title={"Expand"} placement="top">
                    <IconButton
                      size="small"
                      className="mr-2 float-right"
                      onClick={() => {
                        setHideTopics(false);
                      }}
                    >
                      <ExpandMore />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Collapse" placement="top">
                    <IconButton
                      size="small"
                      className="mr-2 float-right"
                      onClick={() => {
                        setHideTopics(true);
                      }}
                    >
                      <ExpandLess />
                    </IconButton>
                  </Tooltip>
                )}
                <TagInfo type="topic" />
              </Box>
            </Box>
          </Card.Title>
        </Card.Header>
        <Card.Body id="topics" className="tag-card-body">
          <Card.Text as="div">
            {topics.map((item: any) => (
              <Badge
                key={item.uuid}
                className={
                  item.weight >= 0.9
                    ? "tag-badge green-tag w3-center w3-animate-zoom"
                    : item.weight < 0.9 && item.weight >= 0.8
                    ? "tag-badge yellowgreen-tag w3-center w3-animate-zoom"
                    : item.weight < 0.8 && item.weight >= 0.7
                    ? "tag-badge yellow-tag w3-center w3-animate-zoom"
                    : item.weight < 0.7 && item.weight >= 0.6
                    ? "tag-badge orange-tag w3-center w3-animate-zoom"
                    : "tag-badge red-tag w3-center w3-animate-zoom"
                }
              >
                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  onEnter={async () => {
                    setHoveredBroader([]);
                    if (!broaderConcepts && item.broader) {
                      await applyBroaderConceptsFallback(item);
                    }
                    setHoveredBroader(getBroaderConcepts(item.uuid));
                  }}
                  overlay={
                    <Popover id={item.title} className="tag-popover">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography component="div">
                          <Box fontWeight="700" mr={4}>
                            {item.title}
                          </Box>
                        </Typography>
                        <Typography
                          className={
                            item.weight >= 0.9
                              ? "green-bottom"
                              : item.weight < 0.9 && item.weight >= 0.8
                              ? "yellowgreen-bottom"
                              : item.weight < 0.8 && item.weight >= 0.7
                              ? "yellow-bottom"
                              : item.weight < 0.7 && item.weight >= 0.6
                              ? "orange-bottom"
                              : "red-bottom"
                          }
                        >
                          ({(100 * item.weight).toFixed(0)}%)
                        </Typography>
                      </Box>

                      {isLoadingBroader ? (
                        <CircularProgress size="1em" />
                      ) : null}

                      {hoveredBroader.length > 0 && (
                        <Box mb={0.4}>
                          {hoveredBroader.map((broader: any, i: number) => {
                            return i === 0 ? (
                              <Typography
                                key={i}
                                style={{ fontWeight: 600 }}
                                variant="inherit"
                                component="a"
                              >
                                <KeyboardReturn
                                  style={{
                                    transform: "rotateY(180deg)",
                                  }}
                                  fontSize="small"
                                />{" "}
                                {broader.title}
                              </Typography>
                            ) : (
                              <Typography
                                style={{ fontWeight: 600 }}
                                variant="inherit"
                                component="a"
                                key={i}
                              >
                                <ChevronRight fontSize="small" />{" "}
                                {broader.title}
                              </Typography>
                            );
                          })}
                        </Box>
                      )}

                      {item.shortDescription && (
                        <Typography component="div" variant="subtitle2">
                          <Box mt={0.4}>{item.shortDescription}</Box>
                        </Typography>
                      )}
                      {item.aliases?.length > 0 && (
                        <Box mt={1}>
                          <Typography
                            variant="caption"
                            style={{ color: "#777" }}
                          >
                            <span className="font-weight-bold">Aliases: </span>
                            {item.aliases.map(
                              (alias: String, index: Number) => {
                                return index !== 0 ? ", " + alias : alias;
                              }
                            )}
                          </Typography>
                        </Box>
                      )}
                      {item.source && (
                        <Typography variant="caption" style={{ color: "#777" }}>
                          <Box fontWeight={500} mt={1}>
                            Source: {item.source}
                          </Box>
                        </Typography>
                      )}
                    </Popover>
                  }
                >
                  <span className="tag-name d-flex align-items-center">
                    <span
                      className="interactive d-flex align-items-center"
                      style={{ flexGrow: 1 }}
                      onClick={() => {
                        setChosenConcept(item);
                        setEditConceptModalShow(true);
                      }}
                    >
                      <LocalOfferOutlined fontSize="small" className="m-1" />
                      {item.title}
                    </span>

                    <IconButton
                      size="small"
                      className="xButton"
                      onClick={(e: any) => {
                        deleteTopic(item);
                        if (tagQualityAssurance)
                          tagQueueArticle({
                            variables: {
                              username: username,
                              finished: false,
                              tags: [
                                ...categories,
                                ...topics.filter((tag: any) => {
                                  return tag !== item;
                                }),
                                ...entities,
                              ],
                            },
                          }).catch(() => {});
                      }}
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  </span>
                </OverlayTrigger>
              </Badge>
            ))}
          </Card.Text>
          <SearchTagsComponent
            type="topic"
            tagQualityAssurance={tagQualityAssurance}
          />
        </Card.Body>
      </Paper>

      <Paper sx={{ width: "100%", zIndex: 3 }}>
        <Card.Header className="tag-header">
          <Card.Title>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <TiThLargeOutline size={titleIconSize} className="mr-2" />
                Entities {entities.length > 0 && `(${entities.length})`}
              </Box>
              <Box display="flex" alignItems="center">
                <LimitSelector
                  value={
                    tagSettings.entities === -1 ||
                    !Number.isInteger(tagSettings.entities)
                      ? ""
                      : tagSettings.entities
                  }
                  onChange={(e: any) => {
                    setTagSettings({
                      ...tagSettings,
                      entities: parseInt(e.target.value),
                    });
                  }}
                  options={[
                    { label: "Default", value: -1 },
                    { label: "0", value: 0 },
                    { label: "5", value: 5 },
                    { label: "10", value: 10 },
                    { label: "15", value: 15 },
                    { label: "25", value: 25 },
                    { label: "50", value: 50 },
                    { label: "100", value: 100 },
                  ]}
                />
                {hideEntities ? (
                  <Tooltip title="Expand" placement="top">
                    <IconButton
                      size="small"
                      className="mr-2 float-right"
                      onClick={() => {
                        setHideEntities(false);
                      }}
                    >
                      <ExpandMore />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Collapse" placement="top">
                    <IconButton
                      size="small"
                      className="mr-2 float-right"
                      onClick={() => {
                        setHideEntities(true);
                      }}
                    >
                      <ExpandLess />
                    </IconButton>
                  </Tooltip>
                )}
                <TagInfo type="entity" />
              </Box>
            </Box>
          </Card.Title>
        </Card.Header>
        <Card.Body id="entities" className="tag-card-body">
          <Card.Text as="div">
            {entities.map((item: any) => (
              <Badge
                key={item.uuid}
                className="tag-badge w3-center w3-animate-zoom"
              >
                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  onEnter={async () => {
                    setHoveredBroader([]);
                    if (!broaderConcepts && item.broader) {
                      await applyBroaderConceptsFallback(item);
                    }
                    setHoveredBroader(getBroaderConcepts(item.uuid));
                  }}
                  overlay={
                    <Popover id={item.uuid} className="tag-popover">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Typography component="span" sx={{ fontWeight: 700 }}>
                          {item.title}
                        </Typography>
                        <Typography component="span">
                          ({item.weight})
                        </Typography>
                      </Box>

                      {isLoadingBroader ? (
                        <CircularProgress size="1em" />
                      ) : null}

                      {hoveredBroader.length > 0 && (
                        <Box mb={0.4}>
                          {hoveredBroader.map((broader: any, i: number) => {
                            return i === 0 ? (
                              <Typography
                                key={i}
                                style={{ fontWeight: 600 }}
                                variant="inherit"
                                component="a"
                              >
                                <KeyboardReturn
                                  style={{
                                    transform: "rotateY(180deg)",
                                  }}
                                  fontSize="small"
                                />{" "}
                                {broader.title}
                              </Typography>
                            ) : (
                              <Typography
                                style={{ fontWeight: 600 }}
                                variant="inherit"
                                component="a"
                                key={i}
                              >
                                <ChevronRight fontSize="small" />{" "}
                                {broader.title}
                              </Typography>
                            );
                          })}
                        </Box>
                      )}

                      {item.shortDescription && (
                        <Typography component="div" variant="subtitle2">
                          <Box mt={0.4}>{item.shortDescription}</Box>
                        </Typography>
                      )}
                      {item.aliases?.length > 0 && (
                        <Box mt={1}>
                          <Typography
                            variant="caption"
                            style={{ color: "#777" }}
                          >
                            <span className="font-weight-bold">Aliases: </span>
                            {item.aliases.map(
                              (alias: String, index: Number) => {
                                return index !== 0 ? ", " + alias : alias;
                              }
                            )}
                          </Typography>
                        </Box>
                      )}
                      <Box mt={1}>
                        {item.source && (
                          <Typography
                            variant="caption"
                            style={{ color: "#777" }}
                          >
                            <Box fontWeight={500} mt={1}>
                              Source: {item.source}
                            </Box>
                          </Typography>
                        )}
                      </Box>
                      {item.geoJSON &&
                      item.geoJSON !== "{}" &&
                      (item.type === "place" ||
                        item.type === "x-im/place" ||
                        item.type === "organisation" ||
                        item.type === "x-im/organisation") ? (
                        <Box width="250px" mt={1}>
                          <CMMap concept={item} size="small" />
                        </Box>
                      ) : null}
                    </Popover>
                  }
                >
                  <div>
                    <span
                      className="tag-name d-flex align-items-center justify-content-between"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      <span
                        className="interactive d-flex align-items-center"
                        style={{ flexGrow: 1 }}
                        onClick={() => {
                          setChosenConcept(item);
                          setEditConceptModalShow(true);
                        }}
                      >
                        {item.type === "x-im/person" ||
                        item.type === "person" ? (
                          <PersonOutlineOutlined
                            fontSize="small"
                            className="m-1"
                          />
                        ) : item.type === "x-im/organisation" ||
                          item.type === "organisation" ? (
                          <AccountBalance fontSize="small" className="m-1" />
                        ) : item.type === "x-im/place" ||
                          item.type === "place" ? (
                          <LocationOn fontSize="small" className="m-1" />
                        ) : item.type === "event" ||
                          item.type === "x-im/event" ? (
                          <EventNote fontSize="small" className="m-1" />
                        ) : item.type === "object" ||
                          item.type === "x-im/object" ? (
                          <TiThLargeOutline size={iconSize} className="m-1" />
                        ) : (
                          <LocalOfferOutlined
                            fontSize="small"
                            className="m-1"
                          />
                        )}
                        {item.title}
                      </span>

                      <IconButton
                        size="small"
                        className="xButton interactive"
                        onClick={(e: any) => {
                          deleteEntity(item);
                          if (tagQualityAssurance) {
                            tagQueueArticle({
                              variables: {
                                username: username,
                                finished: false,
                                tags: [
                                  ...categories,
                                  ...topics,
                                  ...entities.filter((tag: any) => {
                                    return tag !== item;
                                  }),
                                ],
                              },
                            }).catch(() => {});
                          }
                        }}
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    </span>

                    <Box pt={0.2}>
                      <div className="mention mention-line d-flex align-items-center justify-content-center">
                        {item.wikipedia && (
                          <Tooltip title="Visit Wikipedia" placement="top">
                            <IconButton
                              color="primary"
                              className="interactive mr-1"
                              size="small"
                              style={entityButtonStyle}
                              href={
                                item.wikipedia.startsWith("https://")
                                  ? item.wikipedia
                                  : "https://" + item.wikipedia
                              }
                              target="_blank"
                            >
                              <FaWikipediaW size={entityButtonIconSize} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {item.wikidata && (
                          <Tooltip title="Visit Wikidata" placement="top">
                            <IconButton
                              className="interactive mr-1"
                              size="small"
                              style={entityButtonStyle}
                              href={
                                "https://www.wikidata.org/wiki/" + item.wikidata
                              }
                              target="_blank"
                            >
                              <FaDatabase size={entityButtonIconSize} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {item.openStreetMap && (
                          <Tooltip title="Visit OpenStreetMap" placement="top">
                            <IconButton
                              className="interactive mr-1"
                              size="small"
                              style={entityButtonStyle}
                              href={item.openStreetMap}
                              target="_blank"
                            >
                              <FaMapMarkedAlt size={entityButtonIconSize} />
                            </IconButton>
                          </Tooltip>
                        )}

                        {"mentions: " + item.weight}
                      </div>
                    </Box>
                  </div>
                </OverlayTrigger>
              </Badge>
            ))}
          </Card.Text>
          <SearchTagsComponent
            type="entity"
            tagQualityAssurance={tagQualityAssurance}
          />
        </Card.Body>
      </Paper>
    </>
  );

  const NextArticleButton = (
    <Button
      disabled={hasQueueArticle}
      color="primary"
      variant="outlined"
      className="mr-4"
      onClick={() => {
        setIsLoadingNextArticle(true);
        setTagError({
          error: false,
          message: "",
        });
        getQueueArticle({ variables: { username: username } })
          .then((data: any) => {
            setHasQueueArticle(true);
            updateQueueStatistics();
            setIsLoadingNextArticle(false);
            if (data?.data?.queueArticle) {
              const resData = data.data.queueArticle;
              const headline = !resData?.headline
                ? ""
                : "<h3>" + resData.headline + "</h3>";
              const preamble = !resData?.preamble
                ? ""
                : "<p>" + resData.preamble + "</p>";
              const body = !resData?.body
                ? ""
                : "<p>" + resData.body.join("</p><p>") + "</p>";
              const resText = headline + preamble + body;
              let categoryList: any = [];
              let topicList: any = [];
              let entityList: any = [];
              if (resData?.concepts) {
                resData.concepts.forEach((item: any) => {
                  if (
                    item.type === "category" ||
                    item.type === "x-im/category"
                  ) {
                    categoryList = [...categoryList, formatTag(item)];
                  } else if (
                    item.type === "topic" ||
                    item.type === "x-im/topic"
                  ) {
                    topicList = [...topicList, formatTag(item)];
                  } else {
                    entityList = [...entityList, formatTag(item)];
                  }
                });
              }
              JSON.stringify(categoryList) !== JSON.stringify(categories) &&
                setCategories(categoryList);
              JSON.stringify(topicList) !== JSON.stringify(topics) &&
                setTopics(topicList);
              JSON.stringify(entityList) !== JSON.stringify(entities) &&
                setEntities(entityList);
              setEditorText(resText);
              textEditor.setData(resText);
            }
          })
          .catch((e: Error) => {
            setHasQueueArticle(false);
            setTagError({
              error: true,
              message:
                e?.message ||
                "Failed to fetch article, please contact iMatrics",
            });
          })
          .finally(() => {
            setIsLoadingNextArticle(false);
          });
      }}
    >
      <Box component="span" display="flex" alignItems="center" mr={1}>
        {isLoadingNextArticle ? (
          <CircularProgress
            color="primary"
            size="22.5px"
            style={{ padding: "3px" }}
          />
        ) : (
          <NavigateNext />
        )}
      </Box>
      Fetch article
    </Button>
  );

  const SearchArticlesModal = (
    <Box>
      <Button
        variant="outlined"
        className="mr-2"
        onClick={() => {
          setSearchShow(true);
        }}
      >
        <Search fontSize="small" className="mr-1" />
        Search article
      </Button>

      <Modal
        id="modal"
        size="xl"
        show={searchShow}
        animation={false}
        onHide={() => setSearchShow(false)}
      >
        <Modal.Header closeButton>
          <Typography variant="h5">Search articles</Typography>
        </Modal.Header>
        <Modal.Body style={{ padding: 0, minHeight: "33vh" }}>
          <Box sx={{ display: "flex", width: "100%" }}>
            {searchArticlesInput}
            {submittedSearchString && (
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {`${retrievedArticles?.length}`}
                &nbsp;results found for&nbsp;
                <b>{`${submittedSearchString}`}</b>
              </Typography>
            )}
          </Box>
          <Box sx={{ position: "relative" }}>
            <LinearProgress
              color="primary"
              variant={isLoadingArticles ? "indeterminate" : "determinate"}
              value={0}
              sx={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                visibility: isLoadingArticles ? "visible" : "hidden",
                zIndex: 1,
              }}
            />
            <Table striped bordered hover style={{ margin: 0 }}>
              <thead>
                <tr>
                  <th style={{ width: "25%" }}>Title</th>
                  <th style={{ width: "40%" }}>Preamble</th>
                  <th style={{ width: "35%" }}>Tags</th>
                </tr>
              </thead>
              <tbody>
                {retrievedArticles?.length > 0 &&
                  retrievedArticles.map((article: any) => (
                    <Fragment key={article.uuid}>
                      <CustomTooltip
                        arrow
                        placement="bottom"
                        disableInteractive
                        title={
                          <Box>
                            <Box
                              sx={{
                                borderBottom: "1px solid rgba(255,255,255,0.4)",
                                pb: 0.2,
                                mb: 0.4,
                              }}
                            >
                              {article.uuid}
                            </Box>
                            <b>{article.headline}</b>
                            <Box>
                              {article.body.join(" ") !== null ? (
                                <span>{article.body.join(" ")}</span>
                              ) : (
                                <span style={{ color: "red" }}>
                                  No article found
                                </span>
                              )}
                            </Box>
                          </Box>
                        }
                      >
                        <tr
                          className="article-table-row"
                          onClick={() => {
                            textEditor.setData(
                              "<h4>" +
                                article.headline +
                                "</h4>" +
                                "<p>" +
                                article.preamble +
                                "</p>" +
                                "<p>" +
                                article.body.join(" ") +
                                "</p>"
                            );
                            setEditorText(
                              "<h4>" +
                                article.headline +
                                "</h4>" +
                                "<p>" +
                                article.preamble +
                                "</p>" +
                                "<p>" +
                                article.body.join(" ") +
                                "</p>"
                            );
                            setSearchShow(false);
                          }}
                        >
                          <td>
                            <span className="font-weight-bold">
                              {article.headline}
                            </span>
                          </td>
                          <td>
                            <span>
                              {article.preamble === " " ||
                              article.preamble === ""
                                ? article.body[0]
                                : article.preamble}
                            </span>
                          </td>
                          <td>
                            {article.concepts?.map((concept: any) => (
                              <Chip
                                key={concept.uuid}
                                label={concept.title}
                                variant="outlined"
                                color="primary"
                                size="small"
                                sx={{ mr: 0.5, mb: 0.5 }}
                              />
                            ))}
                          </td>
                        </tr>
                      </CustomTooltip>
                    </Fragment>
                  ))}
              </tbody>
            </Table>
          </Box>
        </Modal.Body>
      </Modal>
    </Box>
  );

  const DoneButton = (
    <Button
      disabled={!hasQueueArticle}
      variant="outlined"
      className="mr-2"
      onClick={() => {
        if (
          tagQualityAssurance &&
          Authenticator.hasAuthority([PRIVILEGES.QUEUE])
        ) {
          tagQueueArticle({
            variables: {
              username: username,
              finished: true,
              tags: [...categories, ...topics, ...entities],
            },
          })
            .then(() => {
              setHasQueueArticle(false);
              updateQueueStatistics();
              clearAll();
            })
            .catch(() => {});
        }
      }}
    >
      <Check fontSize="small" className="mr-1" />
      Done
    </Button>
  );

  const ErrorAlert = <Alert severity="error">{tagError.message}</Alert>;

  const DemoPanel = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={1.5}
      sx={{ width: "100%", borderBottom: "1px solid rgba(0,0,0,0.125)" }}
    >
      <Box display="flex" alignItems="center">
        {tagQualityAssurance ? (
          <Fragment>
            {NextArticleButton}
            <SkipButton disabled={!hasQueueArticle} clearAll={clearAll} />
            {DoneButton}
          </Fragment>
        ) : (
          <Fragment>
            {activeEndpoint?.isDemoEnvironment && (
              <PlayDemoButton stopDemo={stopDemo} />
            )}

            <Button variant="outlined" className="mr-2" onClick={clearAll}>
              <DeleteOutlined fontSize="small" className="mr-1" />
              Clear all
            </Button>
            {!activeEndpoint?.isDemoEnvironment && SearchArticlesModal}
          </Fragment>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        {tagQualityAssurance && <QueueStatisticsDisplayer />}
        <DatasetPicker />
      </Box>
    </Box>
  );

  useEffect(() => {
    if (addedConcept) {
      setTimeout(() => {
        getConcept({ variables: addedConcept }).then((data: any) => {
          const res = data?.data?.getConcept?.result;
          if (!res || res?.length < 1) {
            return;
          }
          const newConcept = res[0];
          addConcept(newConcept);
        });
      }, 800);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedConcept]);

  useEffect(() => {
    if (modifiedConcept?.uuid) {
      let newEntities: any[] = entities.map((entity: any) =>
        entity.uuid === modifiedConcept.uuid
          ? { ...entity, ...modifiedConcept }
          : entity
      );
      setEntities(newEntities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedConcept]);

  useEffect(() => {
    if (deletedConcept) {
      let newEntities: any[] = entities.filter(
        (entity: any) => entity.uuid !== deletedConcept
      );
      setEntities(newEntities);
      setDeletedConcept(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedConcept]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <CMConceptModal
        show={editConceptModalShow}
        onHide={() => setEditConceptModalShow(false)}
        concept={chosenConcept}
        setModifiedConcept={setModifiedConcept}
        setDeletedConcept={setDeletedConcept}
      />
      <CMConceptModal
        show={createConceptModalShow}
        onHide={() => setCreateConceptModalShow(false)}
        concept={newEntity}
        setAddedConcept={setAddedConcept}
        createConcept
      />
      <CMConceptModal
        show={createTopicModalShow}
        onHide={() => setCreateTopicModalShow(false)}
        concept={newTopic}
        setAddedConcept={setAddedConcept}
        createConcept
      />
      {DemoPanel}
      <Grid
        container
        item
        sx={{
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          display: "flex",
          flexGrow: 1,
          overflow: "auto", // needed to prevent the grid from overflowing
        }}
      >
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            height: "100%",
            maxHeight: "100%",
            overflow: "hidden",
            pl: 2,
            py: 2,
          }}
        >
          <Editor />
        </Grid>

        <Grid
          item
          gap={2}
          xs={12}
          md={5}
          sx={{
            width: "100%",
            height: "100%",
            maxHeight: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            p: 2,
          }}
        >
          <Grid item xs={12}>
            <AdvancedSettingComponent />
          </Grid>

          {tagError.error && (
            <Grid item xs={12}>
              {ErrorAlert}
            </Grid>
          )}

          {activeEndpoint?.genderAnalysisEnabled && (
            <Grid item xs={12}>
              <GenderDisplayer />
            </Grid>
          )}

          {TagDisplayer()}

          {showImageDisplayer &&
            Authenticator.hasAuthority([PRIVILEGES.IMAGE_TAGGING]) && (
              <Grid item xs={12}>
                <ImageDisplayer />
              </Grid>
            )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AutotaggingDemo;
