import { Autocomplete, TextField, Chip } from "@mui/material";

interface IProps {
  excludeAuthors: string[];
  setExcludeAuthors: (authors: string[]) => void;
}
const AuthorExcludeComponent = (props: IProps) => {
  const { excludeAuthors, setExcludeAuthors } = props;

  return (
    <Autocomplete
      id="exclude-authors-input"
      multiple
      options={[]}
      value={excludeAuthors}
      freeSolo
      size="small"
      limitTags={2}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              variant="outlined"
              size="small"
              label={option}
              key={key}
              {...tagProps}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          variant="outlined"
          label="Exclude authors"
          placeholder="Ex. /TT"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ paddingBottom: 0 }}
        />
      )}
      onChange={(e, newValue) => {
        setExcludeAuthors(newValue);
      }}
      sx={{ width: 220 }}
    />
  );
};

export default AuthorExcludeComponent;
