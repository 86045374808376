import React from "react";
import { Typography, Popover, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import {
  PersonOutlineOutlined,
  AccountBalance,
  LocationOn,
  HelpOutline,
} from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
    },
  })
);

const TagInfo = ({ type }: any) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return type === "entity" ? (
    <span className="float-right icon">
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <HelpOutline className="tag-info interactive" />
      </Typography>
      <Popover
        id="mouse-over-popover"
        style={{
          pointerEvents: "none",
          marginTop: "-2px",
        }}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <span className="info-row">
          <AccountBalance fontSize="small" className="icon" /> ‒ Organisation
        </span>
        <span className="info-row">
          <PersonOutlineOutlined fontSize="small" className="icon" /> ‒ Person
        </span>
        <span className="info-row">
          <LocationOn fontSize="small" className="icon" /> ‒ Place
        </span>
      </Popover>
    </span>
  ) : (
    <span className="float-right icon">
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <HelpOutline className="tag-info interactive" />
      </Typography>
      <Popover
        id="mouse-over-popover"
        style={{
          pointerEvents: "none",
          marginTop: "-2px",
        }}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <span className="info-row green-tag">90 – 100% Relevancy</span>
        <span className="info-row yellowgreen-tag">
          80 – &nbsp;89% Relevancy
        </span>
        <span className="info-row yellow-tag">70 – &nbsp;79% Relevancy</span>
        <span className="info-row orange-tag">60 – &nbsp;69% Relevancy</span>
        <span className="info-row red-tag">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#60; &nbsp;59% Relevancy
        </span>
      </Popover>
    </span>
  );
};

export default TagInfo;
