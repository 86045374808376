import gql from "graphql-tag";
const queueArticle = gql`
  mutation($username: String!) {
    queueArticle(username: $username) {
      preamble
      dateline
      concepts {
        weight
        title
        type
        uuid
        shortDescription
        wikipedia
        wikidata
        openStreetMap
        source
        longDescription
        pubStatus
        aliases
        author
        createdTimestamp
        latestVersionTimestamp
        broader
        geoJSON
      }
      uuid
      headline
      body
    }
  }
`;

export default queueArticle;
