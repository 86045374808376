import gql from "graphql-tag";

const GET_NEWS_RECOMMENDATIONS = gql`
  query ($query: GetRecommendationsInput) {
    getNewsRecommendations(query: $query) {
      recommendationID
      config
      recommendations {
        articleID
        headline
        preamble
        imgSrc
        publicationTimestamp
        topConcept {
          uuid
          title
          type
        }
      }
    }
  }
`;

export default GET_NEWS_RECOMMENDATIONS;
