import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Authenticator } from "../utils/Authenticator";
import { PrivateRoute, PRIVILEGES } from "./PrivateRoute/PrivateRoute";
import CMContext from "./ConceptManagement/CMContext";
import { Box, Grid } from "@mui/material";
// REPLACE BOOTSTRAP WITH MUI

import NoFound from "./NoFound";
import Login from "./Login/Login";
import Dashboard from "./Dashboard/Dashboard";
import SideBar from "./Sidebar/SideBar";
import BreadCrumbs from "./AppBar/BreadCrumbs";
import AutotaggingContext from "./Autotagging/TagContext";
import AutotaggingDemo from "./Autotagging/AutoTaggingDemo";
import ConceptManagement from "./ConceptManagement/ConceptManagement";
import ProductionInsights from "./ProductionInsights/ProductionInsights";
import ResourceManagement from "./ConceptManagement/ResourceManagement/ResourceManagement";
import MissingDataDetector from "./ConceptManagement/MissingDataDetector/MissingDataDetector";
import SettingsModal from "./PanelComponents/SettingsModal";
import Snackbar from "./PanelComponents/Snackbar";
import ConceptSuggestion from "./ConceptManagement/ConceptSuggestions/ConceptSuggestions";
import Newspage from "./iMatricsTimes/Newspage";
import TopicPageManagement from "./iMatricsTimes/TopicPageManagement/TopicPageManagement";
import ApiDocsComponent from "./ApiDocs/ApiDocsComponent";
import CustomAnalysisRulesComponent from "./ConceptManagement/CustomAnalysisRules/CustomAnalysisRulesComponent";
import ConceptExplorerComponent from "./ConceptManagement/ConceptExplorer/ConceptExplorerComponent";
import AdminPanelComponent from "./AdminPanel/AdminPanelComponent";

interface IOption {
  id: string;
  privs?: string[];
  path: string;
  label?: string;
  description?: string;
  image?: string;
}

const options: Array<IOption> = [
  {
    id: "auto-tagging",
    privs: [PRIVILEGES.TAGGING],
    path: "/autotagging",
    label: "Auto-tagging",
    description: "Tag your articles with iMatrics' auto-tagging service",
    image:
      "https://s3.eu-central-1.amazonaws.com/webportal.imatrics.com/imgs/services/auto-tagging.jpg",
  },
  {
    id: "auto-tagging-quality-evaluator",
    privs: [PRIVILEGES.QUALITY_EVALUATOR],
    path: "/auto-tagging-quality-evaluator",
    label: "Auto-tagging Quality Evaluator",
    description: "View the tagging quality of your auto-tagging service",
    image:
      "https://s3.eu-central-1.amazonaws.com/webportal.imatrics.com/imgs/services/auto-tagging-quality-evaluator_small.jpg",
  },
  {
    id: "concept-management",
    privs: [PRIVILEGES.CONCEPT_MANAGEMENT],
    path: "/concept-management",
    label: "Concept Management",
    description: "Manage concepts or the concept database",
    image:
      "https://s3.eu-central-1.amazonaws.com/webportal.imatrics.com/imgs/services/concept-management.jpg",
  },
  {
    id: "production-insights",
    privs: [PRIVILEGES.PRODUCTION_INSIGHTS],
    path: "/production-insights",
    label: "Production Insights",
    description: "Visualize the overview of your production in one place",
    image:
      "https://s3.eu-central-1.amazonaws.com/webportal.imatrics.com/imgs/services/production-insights.jpg",
  },
  {
    id: "api-docs",
    privs: [PRIVILEGES.API_DOCS],
    path: "/api-docs",
    label: "API Docs",
    description: "Integrate iMatrics' services in your system",
    image:
      "https://s3.eu-central-1.amazonaws.com/webportal.imatrics.com/imgs/services/api-docs.jpg",
  },
  {
    id: "imatrics-times",
    privs: [PRIVILEGES.TIMES],
    path: "/imatrics-times",
    label: "iMatrics Times",
    description: "Browse news all around the world in a modern fashion",
    image:
      "https://s3.eu-central-1.amazonaws.com/webportal.imatrics.com/imgs/services/imatrics-times.jpg",
  },
  {
    id: "admin-panel",
    privs: [PRIVILEGES.USER_ADMIN],
    path: "/admin-panel",
    label: "Admin Panel",
    description: "Manage users and their accessibilities",
    image:
      "https://s3.eu-central-1.amazonaws.com/webportal.imatrics.com/imgs/services/admin-panel_small.jpg",
  },
];

const isLoginPage = (location: any) => {
  return location.pathname.indexOf("/login") !== -1;
};

interface Props {
  isAuthorized: boolean;
  setIsAuthorized: (auth: any) => void;
}

const AppContainer = ({ isAuthorized, setIsAuthorized }: Props) => {
  return (
    <BrowserRouter>
      <Route
        render={({ location, history }: any) => {
          const services = [
            {
              id: "dashboard",
              path: "/",
              privileges: null,
              exact: true,
              component: () => <Dashboard options={options} />,
            },
            {
              id: "autotagging",
              path: "/autotagging",
              privileges:
                location.pathname.indexOf(
                  "/autotagging/tag-quality-assurance"
                ) !== -1
                  ? [PRIVILEGES.QUEUE]
                  : [PRIVILEGES.TAGGING],
              exact: false,
              component: () => (
                <AutotaggingContext>
                  <CMContext>
                    <AutotaggingDemo
                      tagQualityAssurance={
                        location.pathname.indexOf(
                          "/autotagging/tag-quality-assurance"
                        ) !== -1
                      }
                    />
                  </CMContext>
                </AutotaggingContext>
              ),
            },

            {
              id: "auto-tagging-quality-evaluator",
              path: "/auto-tagging-quality-evaluator",
              privileges: [PRIVILEGES.QUALITY_EVALUATOR],
              exact: true,
              component: ConceptExplorerComponent,
            },

            {
              id: "concept-management",
              path: "/concept-management",
              privileges:
                location.pathname.indexOf("/concept-management/resources") !==
                  -1 ||
                location.pathname.indexOf(
                  "/concept-management/missing-data"
                ) !== -1
                  ? [PRIVILEGES.ADMIN]
                  : location.pathname.indexOf(
                      "/concept-management/suggestions"
                    ) !== -1
                  ? [PRIVILEGES.CONCEPT_SUGGESTIONS]
                  : [PRIVILEGES.CONCEPT_MANAGEMENT],
              exact: false,
              component: () => (
                <CMContext>
                  {location.pathname.indexOf(
                    "/concept-management/suggestions"
                  ) !== -1 ? (
                    <ConceptSuggestion />
                  ) : location.pathname.indexOf(
                      "/concept-management/resources"
                    ) !== -1 ? (
                    <ResourceManagement />
                  ) : location.pathname.indexOf(
                      "/concept-management/missing-data"
                    ) !== -1 ? (
                    <MissingDataDetector />
                  ) : location.pathname.indexOf(
                      "/concept-management/custom-analysis-rules"
                    ) !== -1 ? (
                    <CustomAnalysisRulesComponent />
                  ) : (
                    <ConceptManagement />
                  )}
                </CMContext>
              ),
            },

            {
              id: "production-insights",
              path: "/production-insights",
              privileges: [PRIVILEGES.PRODUCTION_INSIGHTS],
              exact: true,
              component: ProductionInsights,
            },
            {
              id: "production-overview",
              path: "/production-insights/production-overview",
              privileges: [PRIVILEGES.PRODUCTION_INSIGHTS],
              exact: true,
              component: ProductionInsights,
            },
            {
              id: "concept-statistics",
              path: "/production-insights/concept-statistics",
              privileges: [PRIVILEGES.PRODUCTION_INSIGHTS],
              exact: true,
              component: ProductionInsights,
            },
            {
              id: "topic-page-management",
              path: "/imatrics-times/topic-page-management",
              privileges: [PRIVILEGES.TIMES],
              exact: false,
              component: TopicPageManagement,
            },
            {
              id: "imatrics-times",
              path: "/imatrics-times",
              privileges: [PRIVILEGES.TIMES],
              exact: false,
              component: Newspage,
            },
            {
              id: "api-docs",
              path: "/api-docs",
              privileges: [PRIVILEGES.API_DOCS],
              exact: false,
              component: ApiDocsComponent,
            },
            {
              id: "admin-panel",
              path: "/admin-panel",
              privileges: [PRIVILEGES.USER_ADMIN],
              exact: false,
              component: AdminPanelComponent,
            },
          ];

          return (
            <>
              <Snackbar />
              {!Authenticator.isAuthenticated && <Redirect to="/login" />}
              <Grid container className="app-container">
                {!isLoginPage(location) && (
                  <Grid item xs={2} className="left-col">
                    <SideBar location={location} />
                  </Grid>
                )}

                <Grid
                  container
                  item
                  xs={12}
                  md={!isLoginPage(location) ? 10 : 12}
                  className="right-col"
                  sx={{ maxHeight: "100%", height: "100%" }}
                >
                  <Grid item sx={{ display: "flex", flexGrow: 0 }}>
                    <Box zIndex={7} sx={{ width: "100%" }}>
                      {!isLoginPage(location) && (
                        <>
                          <SettingsModal />
                          <BreadCrumbs
                            location={location}
                            history={history}
                            isAuthorized={isAuthorized}
                            setIsAuthorized={setIsAuthorized}
                          />
                        </>
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexGrow: 1,
                      height: "100%",
                      maxHeight: "100%",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Switch>
                        <Route
                          exact
                          path="/login"
                          component={() => (
                            <Login
                              location={location}
                              setIsAuthorized={setIsAuthorized}
                            />
                          )}
                        />
                        <Route
                          path="/logout"
                          component={() => (
                            <Login
                              location={location}
                              setIsAuthorized={setIsAuthorized}
                            />
                          )}
                        />

                        {services.map((item: any) => (
                          <PrivateRoute
                            key={item.id}
                            path={item.path}
                            exact={item.exact}
                            component={item.component}
                            privileges={item.privileges}
                          />
                        ))}

                        <Route>
                          <NoFound />
                        </Route>
                      </Switch>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        }}
      />
    </BrowserRouter>
  );
};

export default AppContainer;
