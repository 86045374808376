import gql from "graphql-tag";
const getConcept = gql`
  mutation (
    $uuid: String!
    $global: Boolean
    $language: String
    $sourceType: String
    $superAdmin: Boolean
    $extendedAdmin: Boolean
    $quickSearch: Boolean
  ) {
    getConcept(
      uuid: $uuid
      global: $global
      language: $language
      sourceType: $sourceType
      superAdmin: $superAdmin
      extendedAdmin: $extendedAdmin
      quickSearch: $quickSearch
    ) {
      result {
        uuid
        title
        type
        gender
        shortDescription
        longDescription
        pubStatus
        ignore
        aliases
        broader
        broaderConcept {
          uuid
          title
          type
          broader
        }
        author
        geoJSON
        global
        language
        wikipedia
        wikidata
        openStreetMap
        source
        subtypes
        keywords
        keywordConcepts {
          uuid
          rootId
          title
          type
        }
        mustBeMentioned
        mustBeMentionedWords
        correctionWeight
        rootId
        mustConnectWords
        mustNotConnectWords
        links {
          relationType
          id
          uuid
          source
          uri
          url
        }
        createdTimestamp
        latestVersionTimestamp
      }
      response
      error
      scrollID
    }
  }
`;

export default getConcept;
