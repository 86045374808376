import { useState, useEffect, useContext } from "react";
import config from "./config.json";
import { useLazyQuery } from "@apollo/client";
import { Box, Grid, Typography } from "@mui/material";
import GET_NEWS_RECOMMENDATIONS from "../../queries/GET_NEWS_RECOMMENDATIONS";
import { CTX } from "./Context";

import ArticleImageComponent from "./ArticleImageComponent";

interface IConcept {
  uuid: string;
  title: string;
  type: string;
}

interface IRelatedArticle {
  articleID: string;
  headline: string;
  preamble: string;
  publicationTimestamp: string;
  imgSrc: string;
  topConcept: IConcept;
}

interface IProps {
  uuid: string;
}

const BORGHOLM_LOCATION = "56.878, 16.658";

const RelatedArticlesComponent = (props: IProps) => {
  //const { uuid } = props;
  const { history, selectedUserProfile } = useContext(CTX);

  const [relatedArticles, setRelatedArticles] = useState<
    Array<IRelatedArticle>
  >([]);

  const [getRecommendations] = useLazyQuery(GET_NEWS_RECOMMENDATIONS, {
    variables: {
      query: {
        source: "bot",
        returnSize: 3,
        userID: selectedUserProfile.id,
        signedIn: true,
        //uuid: uuid,
        config: "imatrics_times_for_you_config",
        location: BORGHOLM_LOCATION,
        returnFields: [
          "articleID",
          "headline",
          "preamble",
          "imageUrl",
          "publicationTimestamp",
          "topConcept",
          "concepts",
        ],
      },
    },
    onCompleted: (res) => {
      const data = res.getNewsRecommendations;
      const relatedArticles = data.recommendations;
      setRelatedArticles(relatedArticles);
    },
    onError: (e) => {
      console.error("err", e);
    },
  });

  const handleClick = (article: any) => {
    if (window?.getSelection()?.isCollapsed) {
      history.push(
        `${config.BASE_URL + config.PATHS.ARTICLE}?uuid=${article.articleID}`
      );
    }
  };

  useEffect(() => {
    getRecommendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box my="16px">
      <Typography
        component="div"
        variant="h6"
        fontWeight="700"
        boxSizing="border-box"
        color="rgb(10, 38, 69)"
        borderTop="4px solid rgb(10, 38, 69)"
        p={2}
      >
        Related articles
      </Typography>

      <Grid container justifyContent="space-evenly" spacing={4}>
        {relatedArticles?.map((article: IRelatedArticle, i: number) => (
          <Grid item key={i} xs={4}>
            <Box
              width="100%"
              onClick={() => handleClick(article)}
              sx={{ cursor: "pointer" }}
            >
              <ArticleImageComponent
                uuid={article.articleID}
                style={{ aspectRatio: "16/10", objectFit: "cover" }}
              />
              <Box sx={{ py: 1 }}>
                {article?.topConcept?.title && (
                  <Typography
                    sx={{
                      color: "#EF6F6C",
                      fontWeight: 700,
                      fontSize: "0.9rem",
                    }}
                  >
                    {article.topConcept.title.toUpperCase()}
                  </Typography>
                )}
                <Typography
                  sx={{
                    fontFamily: "Crete Round",
                    fontSize: "1.2rem",
                    fontWeight: 700,
                    mb: 0.5,
                  }}
                >
                  {article.headline}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Crete Round",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {article.preamble}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RelatedArticlesComponent;
