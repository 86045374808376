import { Fragment } from "react";

const Unauthorized = () => {
  return (
    <Fragment>
      <div
        style={{
          padding: "1em",
          backgroundColor: "#dcdcdd",
        }}
      >
        <h1 style={{ textAlign: "center" }}>iMatrics' Web Portal</h1>
      </div>

      <div style={{ maxWidth: 700, margin: "auto", paddingTop: "2em" }}>
        <h4>Permission required!</h4>
        <div style={{ flex: 1 }}>
          <p style={{ color: "#f00" }}>You are not authorized.</p>
        </div>
      </div>
    </Fragment>
  );
};

export default Unauthorized;
