import React, { useState, useRef, useEffect, useContext } from "react";
import { CTX as tagCTX } from "./TagContext";
import {
  Box,
  Button,
  ButtonGroup,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  Tooltip,
  ClickAwayListener,
  Popper,
} from "@mui/material";
import { PlayArrow, Stop, ExpandMore } from "@mui/icons-material";

interface IProps {
  stopDemo(): void;
  disabled?: boolean;
}

const PlayDemoButton = (props: IProps) => {
  const { stopDemo, disabled } = props;
  const anchorRef = useRef<HTMLDivElement>(null);
  const {
    demoArticles,
    selectedDemoArticle,
    setSelectedDemoArticle,
    isPlaying,
    setIsPlaying,
  }: any = useContext(tagCTX);

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (demoArticles && demoArticles.length > 0) {
      setSelectedDemoArticle(demoArticles[0]);
      setSelectedIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demoArticles]);

  const handleClick = () => {
    setIsPlaying(true);
  };

  const handleMenuItemClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    i: number
  ) => {
    setSelectedIndex(i);
    setSelectedDemoArticle(demoArticles[i]);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      component="span"
      sx={{ mr: 1, whiteSpace: "nowrap", overflow: "hidden" }}
    >
      <ButtonGroup
        disabled={disabled || !selectedDemoArticle}
        variant="outlined"
        ref={anchorRef}
        aria-label="demo button"
      >
        {!isPlaying ? (
          <Button color="success" onClick={handleClick} sx={{ width: "140px" }}>
            <PlayArrow className="mr-1" />
            Play demo{" "}
            {(selectedDemoArticle && selectedIndex) || selectedIndex === 0
              ? selectedIndex + 1
              : null}
          </Button>
        ) : (
          <Button color="error" onClick={stopDemo} sx={{ width: "140px" }}>
            <Stop className="mr-1" />
            Stop demo
          </Button>
        )}

        <Button
          disabled={isPlaying}
          aria-controls={open ? "demo-articles-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select demo article"
          aria-haspopup="menu"
          onClick={handleToggle}
          size="small"
        >
          <ExpandMore />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="demo-articles-button-menu"
                  style={{ minWidth: "180px" }}
                >
                  {demoArticles.map((article: any, i: number) => (
                    <Tooltip
                      key={i}
                      title={article.headline}
                      placement="right"
                      arrow
                    >
                      <MenuItem
                        selected={i === selectedIndex}
                        onClick={(e) => handleMenuItemClick(e, i)}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box component="span">
                          {`${i + 1 + ". " + article.altHeadline}` ||
                            `Demo article ${i + 1}`}
                        </Box>
                      </MenuItem>
                    </Tooltip>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default PlayDemoButton;
