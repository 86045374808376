import { useState, useEffect } from "react";
import {
  Box,
  Chip,
  TextField,
  IconButton,
  InputAdornment,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Add, ExpandLess } from "@mui/icons-material";

interface Props {
  id: string;
  label: any;
  aliases: string[];
  setAliases: any;
  disabled?: boolean;
  adornment?: any;
  className?: any;
  compact?: boolean;
  isInheriting?: boolean;
  setIsInheriting?: (inherit: boolean) => void;
  isSuperAdmin?: boolean;
  isExtendedAdmin?: boolean;
  mergedAliases?: string[];
}

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      marginRight: "25px",
    },
  })
);

const AliasInput = (props: Props) => {
  const classes = useStyles();
  const {
    id,
    aliases,
    setAliases,
    disabled,
    label,
    adornment,
    className,
    compact,
    isInheriting,
    setIsInheriting,
    mergedAliases,
    isSuperAdmin,
    isExtendedAdmin,
  } = props;

  const [limitTags, setLimitTags] = useState<number>(5);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [aliasInput, setAliasInput] = useState<string>("");
  const [copiedIndex, setCopiedIndex] = useState<number>(-1);

  const handleAddAlias = (alias: string) => {
    if (!alias) return;
    else if (!aliases?.includes(alias) && alias !== "") {
      setAliases([...aliases, alias]);
      setAliasInput("");
    }
  };

  useEffect(() => {
    if (!mergedAliases || isSuperAdmin) {
      return;
    }

    if (isInheriting && isExtendedAdmin) {
      setAliases([...mergedAliases]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInheriting]);

  useEffect(() => {
    if (isExtendedAdmin && !isSuperAdmin) {
      let merged: any[] = [];
      aliases?.forEach((alias: string) => {
        if (!merged.includes(alias)) merged.push(alias);
      });

      try {
        if (JSON.stringify(merged) === JSON.stringify(mergedAliases)) {
          !isInheriting && setIsInheriting && setIsInheriting(true);
        } else {
          isInheriting && setIsInheriting && setIsInheriting(false);
        }
      } catch {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aliases]);

  useEffect(() => {
    if (!expanded) {
      document?.getElementById(id)?.blur();
      setLimitTags(5);
      setAliasInput("");
    } else setLimitTags(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  return (
    <Autocomplete
      id={id}
      className="alias-input"
      classes={{ input: classes.input }}
      disabled={
        isExtendedAdmin && !isSuperAdmin
          ? false
          : isInheriting || disabled
          ? true
          : false
      }
      value={isInheriting ? mergedAliases : aliases ?? []}
      onChange={(e: any, newValue: any) => {
        setAliases(newValue);
        setAliasInput("");
      }}
      fullWidth
      multiple
      limitTags={compact ? 1 : limitTags}
      freeSolo
      autoComplete={false}
      open={false}
      options={[]}
      onFocus={() => {
        setIsFocused(true);
        setExpanded(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      inputValue={aliasInput}
      renderInput={(params) => (
        <TextField
          className={className || undefined}
          style={{
            display: "inline-block",
            whiteSpace: "nowrap",
          }}
          {...params}
          variant="outlined"
          size="small"
          label={label}
          onChange={(e: any) => {
            setAliasInput(e.target.value);
          }}
          onKeyDown={(e: any) => {
            if (e.key === "Backspace") {
              e.stopPropagation();
            }
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <Box position="absolute" right="14px">
                  {isFocused && expanded && (
                    <Tooltip
                      title={label === "Aliases" ? "Add alias" : "Add word"}
                      placement="top"
                    >
                      <span>
                        <IconButton
                          disabled={
                            isExtendedAdmin && !isSuperAdmin
                              ? false
                              : isInheriting || disabled
                              ? true
                              : false
                          }
                          size="small"
                          style={{ marginLeft: "0.3rem" }}
                          onClick={() => {
                            handleAddAlias(aliasInput);
                          }}
                        >
                          <Add />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                  {adornment}

                  {!compact && expanded === true && aliases?.length > 5 && (
                    <Tooltip title="Expand less" placement="top">
                      <span>
                        <IconButton
                          disabled={disabled}
                          size="small"
                          style={{ marginLeft: "0.3rem" }}
                          onClick={() =>
                            setTimeout(() => {
                              setExpanded(false);
                            }, 50)
                          }
                        >
                          <ExpandLess />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </Box>
              </InputAdornment>
            ),
          }}
        />
      )}
      renderTags={(value: string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Tooltip
            key={index}
            arrow
            title={
              <Box fontSize="1.1em">
                <span
                  style={{
                    color: copiedIndex === index ? "#56E39F" : "inherit",
                    fontWeight: "bold",
                  }}
                >
                  {option}
                  {copiedIndex === index && (
                    <span style={{ float: "right", marginLeft: "1em" }}>
                      copied
                    </span>
                  )}
                </span>
              </Box>
            }
          >
            <Chip
              onClick={async () => {
                navigator.clipboard.writeText(aliases[index]);
                setCopiedIndex(index);
                setTimeout(() => setCopiedIndex(-1), 2000);
              }}
              size="small"
              variant="outlined"
              color={
                isExtendedAdmin && !isSuperAdmin
                  ? "default"
                  : isInheriting && mergedAliases?.includes(option)
                  ? "success"
                  : "default"
              }
              label={option}
              {...getTagProps({ index })}
            />
          </Tooltip>
        ))
      }
    />
  );
};

export default AliasInput;
