import { Grid, Paper } from "@mui/material";

import ConceptTopList from "./ConceptTopList";

interface IProps {
  endpoint: any;
  startDate: string;
  endDate: string;
  newspaper: string;
  channel: string;
  excludeAuthors: string[];
  addToConceptList: any;
}

const ConceptTopLists = (props: IProps) => {
  const { endpoint, startDate, endDate, channel, addToConceptList, excludeAuthors } = props;

  const renderTypes = ["category", "topic", "entity"];
  if (endpoint?.tagLimits.category === 0) {
    renderTypes.splice(renderTypes.indexOf("category"), 1);
  }
  if (endpoint?.tagLimits.topic === 0) {
    renderTypes.splice(renderTypes.indexOf("topic"), 1);
  }
  if (endpoint?.tagLimits.entity === 0) {
    renderTypes.splice(renderTypes.indexOf("entity"), 1);
  }

  return (
    <Paper sx={{ mb: 4, width: "100%" }}>
      <Grid container sx={{ width: "100%" }}>
        {renderTypes.map((type: string) => (
          <Grid
            item
            key={type}
            sm={renderTypes.length !== 0 && 12 / renderTypes.length}
            sx={{ width: "100%" }}
          >
            <ConceptTopList
              type={type}
              endpoint={endpoint}
              startDate={startDate}
              endDate={endDate}
              channel={channel}
              excludeAuthors={excludeAuthors}
              addToConceptList={addToConceptList}
              renderTypes={renderTypes}
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default ConceptTopLists;
