import { useContext } from "react";
import { CTX as CMContext } from "./CMContext";
import {
  Box,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  PersonOutlineOutlined,
  LocationOn,
  AccountBalance,
  EventNote,
} from "@mui/icons-material";
import { TiThLargeOutline } from "react-icons/ti";

interface Option {
  value: string;
  label: string;
  icon?: any;
}

const options: Option[] = [
  {
    value: "all",
    label: "All types",
  },
  {
    value: "person",
    label: "Person",
    icon: (
      <PersonOutlineOutlined fontSize="small" style={{ marginRight: "4px" }} />
    ),
  },
  {
    value: "place",
    label: "Place",
    icon: <LocationOn fontSize="small" style={{ marginRight: "4px" }} />,
  },
  {
    value: "organisation",
    label: "Organization",
    icon: <AccountBalance fontSize="small" style={{ marginRight: "4px" }} />,
  },
  {
    value: "event",
    label: "Event",
    icon: <EventNote fontSize="small" style={{ marginRight: "4px" }} />,
  },
  {
    value: "object",
    label: "Object",
    icon: <TiThLargeOutline size="1.2em" style={{ marginRight: "4px" }} />,
  },
];

const SearchFilterComponent = () => {
  const {
    entityFilter,
    setEntityFilter,
    includeDrafts,
    setIncludeDrafts,
  }: any = useContext(CMContext);

  const handleChangeEntityFilter = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEntityFilter(event.target.value);
  };

  const handleChangeDraft = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeDrafts(event.target.checked);
  };

  return (
    <Box display="flex" alignItems="center">
      <TextField
        id="concept-filter-select"
        select
        size="small"
        label="Filter entity types"
        value={entityFilter}
        onChange={handleChangeEntityFilter}
        InputProps={{ style: { fontWeight: 700 } }}
        style={{ width: 175, backgroundColor: "#fff" }}
      >
        {options.map((option: Option) => (
          <MenuItem key={option.value} value={option.value}>
            {option?.icon} {option.label}
          </MenuItem>
        ))}
      </TextField>
      <Box ml={2}>
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={includeDrafts}
              onChange={handleChangeDraft}
            />
          }
          label="Include drafts"
        />
      </Box>
    </Box>
  );
};

export default SearchFilterComponent;
