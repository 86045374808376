import { useState, useEffect } from "react";
import moment from "moment";
import { TextField } from "@mui/material";

interface IProps {
  startDate: string;
  endDate: string;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
}

const DatePicker = (props: IProps) => {
  const { startDate, endDate, setStartDate, setEndDate } = props;

  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);

  useEffect(() => {
    if (startDateError) {
      setTimeout(() => {
        setStartDateError(false);
      }, 4000);
    }
    if (endDateError) {
      setTimeout(() => {
        setEndDateError(false);
      }, 4000);
    }
  }, [startDateError, endDateError]);

  return (
    <form
      noValidate
      style={{
        display: "flex",
        flexWrap: "wrap",
        float: "right",
        gap: 8,
      }}
    >
      <TextField
        id="date-from"
        label="From"
        type="date"
        variant="outlined"
        size="small"
        value={startDate}
        error={startDateError}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: any) => {
          const start = moment(e.target.value);
          const end = moment(endDate);
          const diff = end.diff(start, "days");
          if (diff < 0) {
            setEndDate(start.format("YYYY-MM-DD"));
            setEndDateError(true);
          }
          if (diff > 31) {
            setEndDate(start.add(31, "days").format("YYYY-MM-DD"));
            setEndDateError(true);
          }
          setStartDate(e.target.value);
        }}
        sx={{ width: 170 }}
      />

      <TextField
        id="date-to"
        label="To"
        type="date"
        variant="outlined"
        size="small"
        value={endDate}
        error={endDateError}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: any) => {
          const start = moment(startDate);
          const end = moment(e.target.value);
          const diff = end.diff(start, "days");
          if (diff < 0) {
            setStartDate(end.format("YYYY-MM-DD"));
            setStartDateError(true);
          }
          if (diff > 31) {
            setStartDate(end.subtract(31, "days").format("YYYY-MM-DD"));
            setStartDateError(true);
          }
          setEndDate(e.target.value);
        }}
        sx={{ width: 170 }}
      />
    </form>
  );
};

export default DatePicker;
