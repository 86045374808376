import gql from "graphql-tag";
const GET_QUEUE_STATISTIC = gql`
  mutation ($username: String) {
    getQueueStatistics(username: $username) {
      result {
        userCompleted
        userInProgress
        userSkipped
        inProgress
        completed
        skipped
        lastAssigned
        maxQueue
      }
      error
    }
  }
`;

export default GET_QUEUE_STATISTIC;
