import React, { useState, useEffect, useContext } from "react";
import { Authenticator } from "../../../utils/Authenticator";
import { useMutation } from "@apollo/client";
import { CTX as tagCTX } from "../TagContext";
import {
  Box,
  Paper,
  Typography,
  Popover,
  CircularProgress,
} from "@mui/material";

import GET_QUEUE_STATISTICS from "../../../mutations/getQueueStatistics";

const QueueStatisticsDisplayer = () => {
  const { queueStatisticsTrigger }: any = useContext(tagCTX);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<any>(undefined);
  const [statisticsList, setStatisticsList] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openPopover = Boolean(anchorEl);

  const [getQueueStatistics] = useMutation(GET_QUEUE_STATISTICS);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (statistics) {
      setStatisticsList([
        { label: "User completed:", value: statistics.userCompleted },
        { label: "User skipped:", value: statistics.userSkipped },
        { label: "Total in progress:", value: statistics.inProgress },
        { label: "Total completed:", value: statistics.completed },
        { label: "Total skipped:", value: statistics.skipped },
        { label: "Last assigned:", value: statistics.lastAssigned },
        { label: "Articles:", value: statistics.maxQueue },
      ]);
    }
  }, [statistics]);

  useEffect(() => {
    setIsLoading(true);
    getQueueStatistics({
      variables: {
        username: Authenticator.getUsername(),
      },
    })
      .then((data: any) => {
        if (
          !data?.data?.getQueueStatistics?.error &&
          data?.data?.getQueueStatistics?.result
        )
          setStatistics(data.data.getQueueStatistics.result);
        else setStatistics(null);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueStatisticsTrigger]);

  return (
    <Box mr={1}>
      {isLoading ? (
        <Box component="span" paddingLeft={1} paddingRight={1}>
          <CircularProgress color="primary" size="0.75em" />
        </Box>
      ) : !statistics ? null : (
        <Paper sx={{ cursor: "pointer" }}>
          <Box
            display="flex"
            alignItems="center"
            height="35px"
            p={1}
            aria-owns={openPopover ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <Typography variant="overline">
              <span>Progress: </span>
              <span style={{ fontWeight: 700 }}>
                {statistics.lastAssigned}/{statistics.maxQueue}
              </span>
            </Typography>
          </Box>
          <Popover
            id="mouse-over-popover"
            open={openPopover}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
            slotProps={{ paper: { sx: { p: 1 } } }}
            sx={{
              pointerEvents: "none",
            }}
          >
            {statisticsList.map((statistic: any, i: number) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  minWidth: "175px",
                }}
              >
                <Typography component="span" variant="overline">
                  {statistic.label}
                </Typography>
                <Typography
                  component="span"
                  variant="overline"
                  style={{ fontWeight: "bold" }}
                >
                  {statistic.value}
                </Typography>
              </Box>
            ))}
          </Popover>
        </Paper>
      )}
    </Box>
  );
};

export default QueueStatisticsDisplayer;
