import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Chip,
  LinearProgress,
  Alert,
  Divider,
} from "@mui/material";
import {
  Description,
  Grain,
  Person,
  LocationOn,
  AccountBalance,
  EventNote,
  GridView,
} from "@mui/icons-material";

import SEARCH_ARTICLE from "../../queries/searchArticleQuery";

const getBorderColor = (uuid: String, article: any) => {
  return article.evaluation.correct.some(
    (concept: any) => concept.uuid === uuid
  )
    ? "#56E39F"
    : article.evaluation.incorrect.some((concept: any) => concept.uuid === uuid)
    ? "#EF6F6C"
    : "#FFAD75";
};

const ArticleModal = ({
  chosenArticle,
  articleModalShow,
  handleArticleModalClose,
}: any) => {
  const [article, setArticle] = useState<any>(null);
  const { error, loading, data } = useQuery(SEARCH_ARTICLE, {
    variables: {
      uuid: true,
      searchString: chosenArticle.uuid,
      returnSize: 1,
    },
  });

  useEffect(() => {
    if (data?.searchArticleQuery[0]) {
      setArticle(data.searchArticleQuery[0]);
    }
  }, [data]);

  return (
    <Dialog
      open={articleModalShow}
      onClose={handleArticleModalClose}
      maxWidth="lg"
      fullWidth
    >
      {error ? (
        <Box sx={{ p: 3 }}>
          <Alert severity="error">Error fetching article</Alert>
        </Box>
      ) : loading ? (
        <LinearProgress />
      ) : (
        article && (
          <DialogContent sx={{ p: 3, width: "100%" }}>
            <Typography
              variant="overline"
              sx={{ mb: 1, fontWeight: 700, opacity: 0.8 }}
            >
              {article.uuid}
            </Typography>
            <Typography variant="h4" sx={{ mb: 2 }}>
              {article.headline}
            </Typography>

            {article.preamble && (
              <Typography variant="body1" sx={{ mb: 2 }}>
                {article.preamble}
              </Typography>
            )}
            {article?.body?.length > 0 && (
              <Typography>
                {article.body.map((paragraph: String, index: number) =>
                  index === 0 ? (
                    <Box component="span" key={index}>
                      {paragraph}
                    </Box>
                  ) : (
                    <Box component="span" key={index}>
                      {" " + paragraph}
                    </Box>
                  )
                )}
              </Typography>
            )}

            <Box
              sx={{
                mt: 3,
              }}
            >
              {article?.concepts?.map((concept: any) => (
                <Chip
                  key={concept.uuid}
                  component="span"
                  variant="outlined"
                  label={concept.title}
                  color="primary"
                  sx={{
                    mr: 0.5,
                    mt: 0.5,
                  }}
                  icon={
                    concept.type.includes("category") ? (
                      <Description fontSize="small" />
                    ) : concept.type.includes("topic") ? (
                      <Grain fontSize="small" />
                    ) : concept.type.includes("person") ? (
                      <Person fontSize="small" />
                    ) : concept.type.includes("place") ? (
                      <LocationOn fontSize="small" />
                    ) : concept.type.includes("organisation") ? (
                      <AccountBalance fontSize="small" />
                    ) : concept.type.includes("event") ? (
                      <EventNote fontSize="small" />
                    ) : concept.type.includes("object") ? (
                      <GridView fontSize="small" />
                    ) : undefined
                  }
                />
              ))}
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle2">Tag evaluation:</Typography>
              <Box sx={{ mt: 0.5 }}>
                {chosenArticle?.concepts?.map((concept: any) => (
                  <Chip
                    key={concept.uuid}
                    component="span"
                    variant="outlined"
                    sx={{
                      mr: 0.5,
                      mt: 0.5,
                      borderColor: getBorderColor(concept.uuid, chosenArticle),
                      borderWidth: 3,
                    }}
                    label={concept.title}
                    icon={
                      concept.type.includes("category") ? (
                        <Description fontSize="small" />
                      ) : concept.type.includes("topic") ? (
                        <Grain fontSize="small" />
                      ) : concept.type.includes("person") ? (
                        <Person fontSize="small" />
                      ) : concept.type.includes("place") ? (
                        <LocationOn fontSize="small" />
                      ) : concept.type.includes("organisation") ? (
                        <AccountBalance fontSize="small" />
                      ) : concept.type.includes("event") ? (
                        <EventNote fontSize="small" />
                      ) : concept.type.includes("object") ? (
                        <GridView fontSize="small" />
                      ) : undefined
                    }
                  />
                ))}
              </Box>
            </Box>
          </DialogContent>
        )
      )}

      <DialogActions>
        {article && (
          <Typography style={{ paddingLeft: 16 }} variant="body2">
            <Box component="span" sx={{ mr: 1 }}>
              {article?.authors?.length > 1 ? "Authors" : "Author"}:
            </Box>
            {article.authors &&
              article.authors.map((author: String, index: number) =>
                index === 0 ? (
                  <Box component="span" sx={{ fontWeight: "bold" }} key={index}>
                    {author}
                  </Box>
                ) : (
                  <Box component="span" sx={{ fontWeight: "bold" }} key={index}>
                    {", " + author}
                  </Box>
                )
              )}
            <Box component="span" sx={{ ml: 4 }}>
              {article.dateline}
            </Box>
          </Typography>
        )}
        <Box sx={{ flex: "1 0 0" }} />
        <Button
          variant="outlined"
          onClick={handleArticleModalClose}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArticleModal;
