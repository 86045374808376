import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Chip, CircularProgress } from "@mui/material";

import getConcept from "../../mutations/getConcept";

interface IProps {
  uuid: string;
}

const InterestChip = (props: IProps) => {
  const { uuid } = props;

  const [concept, setConcept] = useState<any>(undefined);

  const [getConceptMutation, { loading, error }] = useMutation(getConcept, {
    variables: {
      uuid: uuid,
    },
    onCompleted: (data) => {
      data = data?.getConcept?.result ?? [];
      if (data.length > 0) {
        setConcept(data[0]);
      }
    },
  });

  useEffect(() => {
    getConceptMutation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error)
    return <Chip variant="outlined" label={uuid} size="small" color="error" />;
  if (loading) return <CircularProgress size="small" color="primary" />;
  if (!concept) return null;

  return (
    <Chip
      variant="outlined"
      label={concept?.title}
      size="small"
      color="primary"
    />
  );
};

export default InterestChip;
