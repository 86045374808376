import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  Paper,
  Typography,
  Chip,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Box,
  Alert,
  Button,
  TextField,
  MenuItem,
  IconButton,
  Tooltip,
  Grid,
} from "@mui/material";
import {
  Description,
  Grain,
  Person,
  LocationOn,
  AccountBalance,
  EventNote,
  GridView,
  StackedBarChart,
} from "@mui/icons-material";

import GET_LATEST_ARTICLES from "../../queries/GET_LATEST_ARTICLES";

import ArticleModal from "./ArticleModal";
import EvaluationBarChart from "./EvaluationBarChart";
import ArticleSearchInput from "./ArticleSearchInput";

interface IConcept {
  uuid: string;
  title: string;
  type: string;
  usage?: number;
}

interface IProps {
  endpoint: any;
  filterConcepts: IConcept[];
  startDate: string;
  endDate: string;
  channel: string;
  newspaper: string;
  includeConcepts: string[];
  excludeAuthors: string[];
}

const getBorderColor = (uuid: String, article: any) => {
  return article.evaluation.correct.some(
    (concept: any) => concept.uuid === uuid
  )
    ? "#56E39F"
    : article.evaluation.incorrect.some((concept: any) => concept.uuid === uuid)
    ? "#EF6F6C"
    : "#FFAD75";
};

const sizeOptions = [10, 50, 100, 200];

const LatestArticleList = (props: IProps) => {
  const {
    endpoint,
    startDate,
    endDate,
    channel,
    includeConcepts,

    excludeAuthors,
  } = props;

  const [articleModalShow, setArticleModalShow] = useState(false);
  const [showEvaluationChart, setShowEvaluationChart] = useState(true);
  const [chosenArticle, setChosenArticle] = useState<any>({
    key: "",
    uuid: "",
  });
  const [returnSize, setReturnSize] = useState<number>(10);
  const [latestArticles, setLatestArticles] = useState<any>(undefined);

  const handleArticleModalClose = () => {
    setArticleModalShow(false);
  };

  const handleViewMore = () => {
    setReturnSize(returnSize + 10);
  };

  const [getLatestArticles, { loading, error }] = useLazyQuery(
    GET_LATEST_ARTICLES,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        query: {
          size: returnSize,
          channels: channel ? [channel] : [],
          concepts: includeConcepts,
          excludeAuthors: excludeAuthors,
        },
      },

      onCompleted: (data) => {
        const articles = data.getLatestArticles.map((article: any) => {
          let formattedArticle = {
            ...article,
            concepts: article.concepts.filter((concept: any) =>
              [
                "category",
                "topic",
                "person",
                "place",
                "organisation",
                "event",
                "object",
              ].includes(concept.type.replace("x-im/", ""))
            ),
          };
          return formattedArticle;
        });
        setLatestArticles(articles);
      },
      onError: (err: any) => {
        setLatestArticles([]);
      },
    }
  );

  useEffect(() => {
    setLatestArticles(undefined);
    setReturnSize(10);
    setArticleModalShow(false);
    setChosenArticle({ key: "", uuid: "" });
    if (startDate && endDate) getLatestArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, channel, startDate, endDate, includeConcepts, excludeAuthors]);

  return (
    <Paper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #e0e0e0",
          bgcolor: "rgba(0, 0, 0, 0.04)",
          gap: 2,
          pr: 1,
        }}
      >
        <Box sx={{ flexGrow: 0 }}>
          <Typography variant="overline" sx={{ pl: 2, fontWeight: "bold" }}>
            Latest published articles
          </Typography>
          <Typography component="span" variant="body2" sx={{ pl: 1 }}>
            ({latestArticles?.length || 0})
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexGrow: 1,
            gap: 1,
          }}
        >
          <TextField
            select
            size="small"
            label="Size"
            value={returnSize}
            onChange={(e) => setReturnSize(parseInt(e.target.value))}
            InputLabelProps={{ shrink: true }}
            InputProps={{ sx: { fontWeight: "bold" } }}
            sx={{ width: "10%", minWidth: 100 }}
          >
            {!sizeOptions.includes(returnSize) && (
              <MenuItem disabled value={returnSize}>
                {returnSize}
              </MenuItem>
            )}
            {sizeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          <Box sx={{ width: "25%", minWidth: 200 }}>
            <ArticleSearchInput />
          </Box>
          <Tooltip
            title={
              (showEvaluationChart ? "Hide" : "Show") + " evaluation chart"
            }
          >
            <IconButton
              color={showEvaluationChart ? "secondary" : "primary"}
              onClick={() => setShowEvaluationChart(!showEvaluationChart)}
            >
              <StackedBarChart />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {loading && (
        <LinearProgress
          sx={{ visibility: loading ? "visible" : "hidden", width: "100%" }}
        />
      )}
      <Grid container>
        <Grid item md={showEvaluationChart ? 9 : 12}>
          <Box
            sx={{
              overflowX: "hidden",
              overflowY: "auto",
              minHeight: 420,
              maxHeight: "60vh",
            }}
          >
            {(error || latestArticles?.length === 0) && (
              <Alert severity="error" sx={{ display: "inline-flex", m: 2 }}>
                No article data found for the selected dataset.
              </Alert>
            )}
            <ArticleModal
              chosenArticle={chosenArticle}
              articleModalShow={articleModalShow}
              handleArticleModalClose={handleArticleModalClose}
            />

            <List dense disablePadding>
              {latestArticles?.map((article: any) => (
                <ListItem
                  key={article.uuid}
                  button
                  onClick={() => {
                    setChosenArticle(article);
                    setArticleModalShow(true);
                  }}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ mb: article.concepts.length > 0 ? 0.5 : 0 }}>
                        <Typography
                          component="span"
                          sx={{ fontWeight: "bold" }}
                        >
                          {article.headline}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#777",
                            float: "right",
                            fontWeight: "bold",
                          }}
                        >
                          {new Date(
                            article.publicationTimestamp
                          ).toLocaleTimeString([], {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <Box
                        component="span"
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box component="span">
                          {article?.concepts.map(
                            (concept: IConcept, i: number) => {
                              return (
                                <Chip
                                  key={concept.uuid || i}
                                  component="span"
                                  variant="outlined"
                                  sx={{
                                    mr: 0.5,
                                    mt: 0.5,
                                    borderColor: getBorderColor(
                                      concept.uuid,
                                      article
                                    ),
                                    borderWidth: 3,
                                  }}
                                  label={concept.title}
                                  icon={
                                    concept.type.includes("category") ? (
                                      <Description fontSize="small" />
                                    ) : concept.type.includes("topic") ? (
                                      <Grain fontSize="small" />
                                    ) : concept.type.includes("person") ? (
                                      <Person fontSize="small" />
                                    ) : concept.type.includes("place") ? (
                                      <LocationOn fontSize="small" />
                                    ) : concept.type.includes(
                                        "organisation"
                                      ) ? (
                                      <AccountBalance fontSize="small" />
                                    ) : concept.type.includes("event") ? (
                                      <EventNote fontSize="small" />
                                    ) : concept.type.includes("object") ? (
                                      <GridView fontSize="small" />
                                    ) : undefined
                                  }
                                />
                              );
                            }
                          )}
                        </Box>
                        <Box component="span" sx={{ whiteSpace: "nowrap" }}>
                          {article?.concepts.length +
                            ` ${article?.concepts.length > 1 ? "tags" : "tag"}`}
                        </Box>
                      </Box>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          {latestArticles?.length > 0 && (
            <Button
              variant="contained"
              fullWidth
              onClick={handleViewMore}
              sx={{
                borderRadius: showEvaluationChart ? "0 0 0 4px" : "0 0 4px 4px",
              }}
            >
              See more
            </Button>
          )}
        </Grid>
        {showEvaluationChart && latestArticles?.length > 0 && (
          <Grid item md={3} sx={{ px: 1, py: 3 }}>
            <EvaluationBarChart data={latestArticles} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default LatestArticleList;
