import gql from "graphql-tag";
const tagQueueArticle = gql`
  mutation ($username: String!, $finished: Boolean!, $tags: [ConceptInput]) {
    tagQueueArticle(username: $username, finished: $finished, tags: $tags) {
      concepts {
        weight
        title
        type
        uuid
        shortDescription
        wikipedia
        wikidata
        openStreetMap
        source
        longDescription
        pubStatus
        aliases
        author
        createdTimestamp
        latestVersionTimestamp
        broader
        geoJSON
        global
        language
      }
      queueNr
      isDone
    }
  }
`;

export default tagQueueArticle;
