import React, { useState } from "react";
import { Authenticator } from "../../utils/Authenticator";
import { Redirect } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { useQuery, useMutation } from "@apollo/client";

import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Box,
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Checkbox,
  Typography,
  Alert,
  CircularProgress,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import {
  Person,
  Lock,
  Visibility,
  VisibilityOff,
  Email,
  CheckCircleOutlined,
  ErrorOutlineOutlined,
} from "@mui/icons-material";
import LOGIN_USER from "../../mutations/loginMutation";
import DEMO_SIGNUP from "../../mutations/submitDemoRequest";
import GET_DEMO_LANGUAGES from "../../queries/GET_DEMO_LANGUAGES";

import bg from "../../images/bgs/login-bg.jpg";
import loginlogo from "../../images/logo/iMatrics-blue-logo.svg";

//import TermsModal from "./TermsModal";
//import PolicyModal from "./PolicyModal";

const theme = createTheme();
const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        backgroundImage: `url(${bg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "100%",
        overflow: "auto",
        display: "flex",
        justifyContent: "center",

        [theme.breakpoints.down("sm")]: {
          overflow: "auto",
        },
      },
      gridRoot: {
        maxWidth: "1200px",
      },
      card: {
        width: "36vw",
        maxWidth: "475px",
        minWidth: "325px",
        verticalAlign: "middle",
        margin: "auto",
        borderRadius: "14px",
        color: "black",
        fontWeight: "bold",
        overflow: "hidden",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        padding: "32px",
        marginBottom: "32px",

        [theme.breakpoints.down("sm")]: {
          marginLeft: theme.spacing(3),
        },
      },
      paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      logo: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        marginBottom: "24px",
      },
      form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: "24px",
      },
      formControl: {
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: "16px",
      },
      cardText: {
        textAlign: "center",
      },
      picker: {
        fontWeight: "bold",
        backgroundColor: "#fff",
      },
      linkText: {
        fontWeight: 700,
        cursor: "pointer",
        "&:hover": {
          opacity: 0.75,
        },
      },
      formItem: {
        marginBottom: "32px",
      },
    }),
  { index: 1 }
);

const labelStyle = {
  backgroundColor: "#fff",
  padding: "4px 12px 0px 12px",
  borderRadius: "4px",
  marginTop: "-4px",
};

const flagStyle = {
  width: "1.3rem",
  height: "1.3rem",
  objectFit: "cover",
  borderRadius: "50%",
};

const compare: any = (a: any, b: any) => {
  if (a.language_name < b.language_name) {
    return -1;
  }
  if (a.language_name > b.language_name) {
    return 1;
  }
  return 0;
};

/** Method to convert country code to the actual code in order to render the country flag */
const convertCountryCode: any = (country: string) => {
  if (!country) return "";
  if (country === "sv") country = "se";
  if (country === "en") country = "us";
  if (country === "da") country = "dk";
  return country.toUpperCase();
};

interface Props {
  location: any;
  setIsAuthorized: (authorized: boolean) => void;
}

interface State {
  formUsername: string;
  formPassword: string;
  errorMessage: string;
  showPassword: boolean;
  signupName: string;
  signupEmail: string;
  signupCheckbox: boolean;
  signupLanguage: string;
}

const Login = (props: Props) => {
  const classes = useStyles();
  const { location, setIsAuthorized } = props;

  const [state, setState] = useState<State>({
    formUsername: "",
    formPassword: "",
    errorMessage: "",
    showPassword: false,
    signupName: "",
    signupEmail: "",
    signupCheckbox: false,
    signupLanguage: "sv",
  });

  const [languages, setLanguages] = useState([
    { language_name: "Dutch", language_code: "nl" },
    { language_name: "English", language_code: "en" },
    { language_name: "Finnish", language_code: "fi" },
    { language_name: "German", language_code: "de" },
    { language_name: "Swedish", language_code: "sv" },
  ]);

  //const [openTerms, setOpenTerms] = useState(false);
  //const [openPolicy, setOpenPolicy] = useState(false);

  const logoutPath = location.pathname === "/logout";
  const { from } = location.state || { from: { pathname: "/" } };
  const redirectToReferrer = Authenticator.isAuthenticated();

  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [isLoadingSignUp, setIsLoadingSignUp] = useState(false);
  const [successSignup, setSuccessSignup] = useState(false);
  const [successSignupMessage, setSuccessSignupMessage] = useState("");
  const [errorSignup, setErrorSignup] = useState(false);
  const [errorSignupMessage, setErrorSignupMessage] = useState("");

  const [demoSignup] = useMutation(DEMO_SIGNUP);
  const [loginUser] = useMutation(LOGIN_USER);

  const { loading } = useQuery(GET_DEMO_LANGUAGES, {
    onError: (err) => {
      console.error(err);
    },
    onCompleted: (data: any) => {
      if (data?.getDemoLanguages) {
        const languagesSorted: any = [...data.getDemoLanguages].sort(compare);
        setLanguages(languagesSorted);
      }
    },
  });

  const login = async (userData: any) => {
    Authenticator.authenticate(userData);
  };
  const logout = async () => {
    Authenticator.signout();
  };
  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleLoginSubmit = (e: any) => {
    e.preventDefault();
    setState({
      ...state,
      errorMessage: "",
    });
    setIsLoadingLogin(true);
    loginUser({
      variables: {
        username: state.formUsername,
        password: state.formPassword,
      },
    })
      .then((data) => {
        login(data).then(() => {
          setIsAuthorized(true);
        });
      })
      .catch((e: Error) => {
        console.error("e", e);
        setState({
          ...state,
          errorMessage: e.message,
        });
      })
      .finally(() => setIsLoadingLogin(false));
  };

  const handleSignUpSubmit = (e: any) => {
    e.preventDefault();
    if (!state.signupName) {
      setErrorSignup(true);
      setErrorSignupMessage("You need to fill in your full name to proceed.");
      return;
    }
    if (!state.signupEmail) {
      setErrorSignup(true);
      setErrorSignupMessage("You need to fill in your email to proceed.");
      return;
    }
    if (!state.signupCheckbox) {
      setErrorSignup(true);
      setErrorSignupMessage("You need to agree to the terms to proceed.");
      return;
    }
    if (!state.signupLanguage) {
      setErrorSignup(true);
      setErrorSignupMessage("You need to select a language to proceed.");
      return;
    }

    setErrorSignup(false);
    setSuccessSignup(false);
    setIsLoadingSignUp(true);
    demoSignup({
      variables: {
        email: state.signupEmail,
        language: state.signupLanguage,
        name: state.signupName,
      },
    })
      .then((data: any) => {
        const response = data?.data?.submitDemoRequest;
        if (response.error) {
          setErrorSignup(true);
          setErrorSignupMessage(response.message);
        } else {
          setSuccessSignup(true);
          setSuccessSignupMessage(response.message);
        }
      })
      .catch(() => {
        setErrorSignup(true);
        setErrorSignupMessage(
          "Something went wrong, please try again or contact iMatrics at contact@imatrics.com"
        );
      })
      .finally(() => setIsLoadingSignUp(false));
  };

  if (logoutPath) {
    logout().then(() => {
      setIsAuthorized(false);
    });
    return <Redirect to="/" />;
  }
  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }

  return (
    <Box className={classes.root} p={12}>
      <Grid
        container
        spacing={0}
        justifyContent="center"
        className={classes.gridRoot}
      >
        <Grid item sm={6}>
          <Box className={classes.card}>
            <img src={loginlogo} alt="iMatrics-logo" className={classes.logo} />
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Box className={classes.cardText}>
                  <Typography variant="h6">
                    Already have an account?
                    <br />
                    Log in here!
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <form
                  autoComplete="off"
                  onSubmit={handleLoginSubmit}
                  name="login-form"
                  method="POST"
                >
                  <TextField
                    name="username"
                    autoFocus
                    id="username"
                    value={state.formUsername}
                    autoComplete="username"
                    placeholder="Enter username"
                    type="text"
                    label="Username"
                    variant="outlined"
                    size="small"
                    fullWidth
                    //className={classes.formItem}
                    style={{
                      marginBottom: "32px",
                    }}
                    InputLabelProps={{
                      style: labelStyle,
                    }}
                    InputProps={{
                      style: { backgroundColor: "#fff" },
                      startAdornment: (
                        <InputAdornment className="ml-2" position="start">
                          <Person />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          <span />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event: any) => {
                      setState({
                        ...state,
                        formUsername: event.target.value,
                      });
                    }}
                  />

                  <TextField
                    name="password"
                    id="password"
                    placeholder="Enter password"
                    value={state.formPassword}
                    type={state.showPassword ? "text" : "password"}
                    label="Password"
                    autoComplete="off"
                    variant="outlined"
                    size="small"
                    fullWidth
                    //className={classes.formItem}
                    style={{
                      marginBottom: "32px",
                    }}
                    InputLabelProps={{
                      style: labelStyle,
                    }}
                    InputProps={{
                      style: { backgroundColor: "#fff" },
                      startAdornment: (
                        <InputAdornment className="ml-2" position="start">
                          <Lock />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {state.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={function (event: any) {
                      setState({
                        ...state,
                        formPassword: event.target.value,
                      });
                    }}
                  />

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    {isLoadingLogin && <CircularProgress size="1.2em" />}
                    <Button
                      type="submit"
                      className="login-button float-right"
                      style={{ marginLeft: "1.2em" }}
                      variant="contained"
                      color="primary"
                    >
                      Log in
                    </Button>
                  </Box>
                </form>
              </Grid>

              {state.errorMessage && (
                <Grid item xs={12}>
                  <Alert
                    icon={<ErrorOutlineOutlined fontSize="large" />}
                    severity="error"
                  >
                    <Box
                      display="flex"
                      height="100%"
                      alignItems="center"
                      fontSize="1.2em"
                      fontWeight="bold"
                    >
                      {state.errorMessage}
                    </Box>
                  </Alert>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box className={classes.card}>
            <Box>
              <form
                name="sign-up-form"
                className={classes.form}
                onSubmit={handleSignUpSubmit}
                method="POST"
              >
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Box className={classes.cardText}>
                      <Typography variant="h6">
                        Want to try out iMatrics' services?
                        <br />
                        Create your demo account here!
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="full-name"
                      name="full-name"
                      label="Name (required)"
                      value={state.signupName}
                      autoComplete="full-name"
                      variant="outlined"
                      fullWidth
                      placeholder="Enter name"
                      type="text"
                      size="small"
                      InputLabelProps={{
                        style: labelStyle,
                      }}
                      InputProps={{
                        style: { backgroundColor: "#fff" },
                        startAdornment: (
                          <InputAdornment className="ml-2" position="start">
                            <Person />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="start">
                            <span />
                          </InputAdornment>
                        ),
                      }}
                      onChange={function (event: any) {
                        setState({
                          ...state,
                          signupName: event.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="email"
                      name="email"
                      label="Email address (required)"
                      value={state.signupEmail}
                      autoComplete="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Enter email"
                      type="text"
                      size="small"
                      InputLabelProps={{ style: labelStyle }}
                      InputProps={{
                        style: { backgroundColor: "#fff" },
                        startAdornment: (
                          <InputAdornment className="ml-2" position="start">
                            <Email fontSize="small" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="start">
                            <span />
                          </InputAdornment>
                        ),
                      }}
                      onChange={function (event: any) {
                        setState({
                          ...state,
                          signupEmail: event.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      disabled={loading}
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth
                      size="small"
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        style={labelStyle}
                      >
                        Language
                      </InputLabel>
                      <Select
                        name="language"
                        id="language-picker"
                        labelId="language-picker-label"
                        label="Language"
                        value={state.signupLanguage}
                        className={classes.picker}
                        onChange={function (event: any) {
                          setState({
                            ...state,
                            signupLanguage: event.target.value,
                          });
                        }}
                      >
                        {languages.map((language: any, index: number) => (
                          <MenuItem value={language.language_code} key={index}>
                            <Box component="a" mx={1}>
                              <ReactCountryFlag
                                id={`country-flag-${language.language_code}`}
                                countryCode={convertCountryCode(
                                  language.language_code
                                )}
                                svg
                                style={flagStyle}
                                title={language.language_code}
                              />
                            </Box>
                            {language.language_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={2}>
                      <Checkbox
                        name="terms"
                        color="primary"
                        checked={state.signupCheckbox}
                        onClick={(e: any) => {
                          setState({
                            ...state,
                            signupCheckbox: e.target.checked,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography component="div">
                        I agree to the{" "}
                        <a
                          href="https://imatrics.com/webportal-terms-and-conditions/"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.linkText}
                        >
                          Terms and Conditions
                        </a>
                        , the{" "}
                        <a
                          href="https://imatrics.com/privacy-policy/"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.linkText}
                        >
                          Privacy Policy
                        </a>
                        <span>
                          , and confirm that iMatrics may contact me via email.
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      Create account
                    </Button>
                  </Grid>

                  {isLoadingSignUp && (
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                      >
                        <CircularProgress size="3em" />
                      </Box>
                    </Grid>
                  )}

                  {errorSignup && (
                    <Grid item xs={12}>
                      <Alert
                        icon={<ErrorOutlineOutlined fontSize="large" />}
                        severity="error"
                      >
                        <Box
                          display="flex"
                          height="100%"
                          alignItems="center"
                          fontSize="1.2em"
                          fontWeight="bold"
                        >
                          {errorSignupMessage}
                        </Box>
                      </Alert>
                    </Grid>
                  )}
                  {successSignup && (
                    <Grid item xs={12}>
                      <Alert
                        icon={<CheckCircleOutlined fontSize="large" />}
                        severity="success"
                      >
                        <Box
                          display="flex"
                          height="100%"
                          alignItems="center"
                          fontSize="1.2em"
                          fontWeight="bold"
                        >
                          {successSignupMessage}
                        </Box>
                      </Alert>
                    </Grid>
                  )}
                </Grid>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
