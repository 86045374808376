import { gql } from "@apollo/client";

const GET_LATEST_CONCEPTS = gql`
  query ($query: LatestConceptInput) {
    getLatestConcepts(query: $query) {
      uuid
      title
      type
      shortDescription
      author
      createdTimestamp
      latestVersionTimestamp
    }
  }
`;

export default GET_LATEST_CONCEPTS;
