import { useState, useEffect, useContext } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Box, LinearProgress } from "@mui/material";

import { CTX as tagCTX } from "./TagContext";

let isFetching: boolean = false;

const unwrapText = (text: string) => {
  text = text.replaceAll("</p>", "</p>\n");
  text = text.replaceAll("<br>", "\n");
  text = text.replaceAll("</h1>", "</h1>\n");
  text = text.replaceAll("</h2>", "</h2>\n");
  text = text.replaceAll("</h3>", "</h3>\n");
  text = text.replaceAll("</h4>", "</h4>\n");
  text = text.replaceAll(/<[^>]*>?/gm, "");
  return text;
  //return text.replaceAll(/&nbsp;/g, " ");
};

const Editor = () => {
  const {
    categories,
    setCategories,
    topics,
    setTopics,
    entities,
    setEntities,
    setBroaderConcepts,
    broaderConceptsFallback,
    setBroaderConceptsFallback,
    analyzeArticle,
    editorText,
    setEditorText,
    setTextEditor,
    setTagError,

    formatTag,
    clearTags,
    setGenderData,
    tagQualityAssurance,
    isPlaying,
    forceTagging,
    taggingTimeouts,
    setTaggingTimeouts,
    clearTimeouts,

    tagSettings,
    articleFormat,
  }: any = useContext(tagCTX);

  const [editorLoaded, setEditorLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formatArticleToJSON = (text: string) => {
    let res: any = {
      headline: "",
      preamble: "",
      body: [],
      dateline: "",
      images: [
        {
          caption: "",
        },
        {
          caption: "",
        },
      ],
    };
    const html = new DOMParser().parseFromString(text, "text/html");
    const htmlContent = html.body;
    res.headline = unwrapText($(htmlContent)?.find("h1")?.text());
    res.preamble = unwrapText($(htmlContent)?.find("h2")?.text());
    res.dateline = unwrapText($(htmlContent)?.find("h3")?.text());
    //res.image. = unwrapText($(htmlContent)?.find("h2")?.text());
    res.body = $(htmlContent)
      ?.find("p")
      ?.get()
      ?.map((p: any) => unwrapText(p.innerHTML));

    return res;
  };

  useEffect(() => {
    isFetching = isLoading;
  }, [isLoading]);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const editorConfiguration = {
    ForcePasteAsPlainText: true,
    startupFocus: true,
    enterMode: "2",
    language: "en",
    autoParagraph: false,
    heading: {
      options: [
        {
          model: "heading1",
          view: "h1",
          title: "Headline",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Preamble",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Dateline",
        },
        {
          model: "paragraph",
          view: "p",
          title: "Paragraph",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Image text",
        },
      ],
    },
    ckfinder: {
      uploadUrl:
        "https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json",
    },
  };

  const editorHandleChange = (editor: any) => {
    //unwrap all img elems on pasting content etc.
    let resText = editor.getData().replaceAll(/<img[^>]*>/g, "");
    resText = resText.replaceAll("<a[^>]*>(.*?)</a>", "");
    resText = resText.replaceAll(/<\/?figure[^>]*>/g, "");
    resText = resText.replaceAll(/<[^/>][^>]*><\/[^>]+>/gim, "");
    if (resText === "") clearTags();
    setEditorText(resText);
  };

  return (
    <Box id="editor">
      {editorLoaded && (
        <Box sx={{ position: "relative" }}>
          <LinearProgress
            color="primary"
            variant={isLoading ? "indeterminate" : "determinate"}
            value={0}
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              visibility: isLoading ? "visible" : "hidden",
              zIndex: 1,
            }}
          />

          <CKEditor
            id="demo-editor"
            name="demo-editor"
            type="text"
            data={editorText}
            config={editorConfiguration}
            onInit={(editor: any) => {
              setTextEditor(editor);
              editor.editing.view.focus();
            }}
            disabled={tagQualityAssurance ? true : false}
            editor={ClassicEditor}
            onChange={(e: any, editor: any) => {
              if (tagQualityAssurance) return;
              if (!isPlaying) editorHandleChange(editor);
              const content = editor.getData();
              if (!content) return;
              if (
                (!isPlaying && !tagQualityAssurance && unwrapText(content)) ||
                forceTagging
              ) {
                setTagError({
                  error: false,
                  message: "",
                });
                let taggingTimeout = setTimeout(() => {
                  if (isFetching) {
                    return;
                  }
                  setTaggingTimeouts([]);
                  setIsLoading(true);

                  let settings = {
                    articleFormat: undefined,
                    categories: tagSettings.categories,
                    topics: tagSettings.topics,
                    entities: tagSettings.entities,
                  };
                  let articleData = unwrapText(content);
                  if (articleFormat === "json") {
                    articleData = JSON.stringify(formatArticleToJSON(content));
                    settings = { ...tagSettings, articleFormat: articleFormat };
                  }
                  analyzeArticle({
                    variables: {
                      articleText: articleData,
                      settings: settings,
                    },
                  })
                    .then((data: any) => {
                      //setLatestTagged(unwrapText(editor.getData()));

                      let categoryList: any = [];
                      let topicList: any = [];
                      let entityList: any = [];
                      let concepts: any = [];

                      concepts = data?.data?.analyzeArticle?.concepts || [];
                      setGenderData(
                        data?.data?.analyzeArticle?.genderData || {
                          maleScore: 0,
                          maleMentions: 0,
                          uniqueMaleEntities: 0,
                          malePeople: [],
                          femaleScore: 0,
                          femaleMentions: 0,
                          uniqueFemaleEntities: 0,
                          femalePeople: [],
                          nonbinaryScore: 0,
                          nonbinaryMentions: 0,
                          uniqueNonbinaryEntities: 0,
                          nonbinaryPeople: [],
                          unknownScore: 0,
                          unknownMentions: 0,
                          uniqueUnknownEntities: 0,
                          unknownPeople: [],
                        }
                      );

                      if (data?.data?.analyzeArticle?.broader) {
                        setBroaderConcepts(data.data.analyzeArticle.broader);
                        data.data.analyzeArticle.broader.forEach(
                          (broader: any) => {
                            if (
                              !concepts.some(
                                (concept: any) => concept.uuid === broader.uuid
                              )
                            ) {
                              concepts.push(broader);
                            }
                          }
                        );
                        if (broaderConceptsFallback.length > 0)
                          setBroaderConceptsFallback([]);
                      } else {
                        setBroaderConcepts(null);
                      }

                      concepts.forEach((item: any) => {
                        delete item.__typename;
                        if (
                          item.type === "x-im/category" ||
                          item.type === "category"
                        ) {
                          categoryList = [...categoryList, formatTag(item)];
                        } else if (
                          item.type === "x-im/topic" ||
                          item.type === "topic"
                        ) {
                          topicList = [...topicList, formatTag(item)];
                        } else if (
                          item.type === "x-im/person" ||
                          item.type === "x-im/organisation" ||
                          item.type === "x-im/place" ||
                          item.type === "x-im/event" ||
                          item.type === "x-im/object" ||
                          item.type === "person" ||
                          item.type === "organisation" ||
                          item.type === "place" ||
                          item.type === "event" ||
                          item.type === "object"
                        ) {
                          entityList = [...entityList, formatTag(item)];
                        } else {
                          entityList = [...entityList, formatTag(item)];
                        }
                      });
                      JSON.stringify(categoryList) !==
                        JSON.stringify(categories) &&
                        setCategories(categoryList);
                      JSON.stringify(topicList) !== JSON.stringify(topics) &&
                        setTopics(topicList);
                      JSON.stringify(entityList) !== JSON.stringify(entities) &&
                        setEntities(entityList);
                    })
                    .catch((e: Error) => {
                      setTagError({
                        error: true,
                        message:
                          e?.message +
                          ". Contact iMatrics if this problem persists.",
                      });
                    })
                    .finally(() => {
                      setIsLoading(false);
                    });
                }, 2000);
                if (!forceTagging) {
                  clearTimeouts(taggingTimeouts);
                }
                setTaggingTimeouts([...taggingTimeouts, taggingTimeout]);
              }
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Editor;
