import React, { Fragment, useState, useEffect, useContext } from "react";
import { Authenticator } from "../../utils/Authenticator";
import { Link } from "react-router-dom";
import { CTX } from "../../utils/ContextStore";
// REPLACE BOOTSTRAP
import { Navbar, Breadcrumb } from "react-bootstrap";
import {
  Typography,
  List,
  MenuItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Menu,
  Avatar,
  Divider,
} from "@mui/material";
import {
  Loyalty,
  Public,
  Poll,
  FindInPage,
  SettingsInputComponent,
  Settings,
  AccountCircle,
  ExitToApp,
} from "@mui/icons-material";

import BurgerMenu from "./BurgerMenu";

interface MenuOption {
  label: string;
  url: string;
  icon?: any;
  subOptions?: MenuOption[] | null | undefined;
}

const menuOptions: MenuOption[] = [
  {
    label: "Auto-tagging",
    url: "/autotagging",
    icon: <Loyalty />,
    subOptions: [
      {
        label: "Tag Quality Assurance",
        url: "/autotagging/tag-quality-assurance",
      },
    ],
  },
  {
    label: "Concept Management",
    url: "/concept-management",
    icon: <Public />,
    subOptions: [
      {
        label: "Concept Suggestions",
        url: "/concept-management/suggestions",
      },
      {
        label: "Resource Management",
        url: "/concept-management/resources",
      },
      {
        label: "Missing Data Detector",
        url: "/concept-management/missing-data",
      },
    ],
  },
  {
    label: "iMatrics Times",
    url: "/imatrics-times",
    icon: <Public />,
    subOptions: [
      {
        label: "Manage Topics",
        url: "/imatrics-times/topic-page-management",
      },
    ],
  },
  { label: "Article Search", url: "/article-search", icon: <FindInPage /> },
  {
    label: "Production Insights",
    url: "/production-insights",
    icon: <Poll />,
    subOptions: [
      {
        label: "Production Overview",
        url: "/production-insights/production-overview",
      },
      {
        label: "Concept Statistics",
        url: "/production-insights/concept-statistics",
      },
    ],
  },
  {
    label: "API Docs",
    url: "/api-documentation",
    icon: <SettingsInputComponent />,
  },
];

interface IProps {
  location: any;
  history: any;
  isAuthorized: boolean;
  setIsAuthorized: (auth: any) => void;
}

const BreadCrumbs = (props: IProps) => {
  const { location, history, isAuthorized, setIsAuthorized } = props;
  const { setShowSettings }: any = useContext(CTX);

  const [initials, setInitials] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isLoggedIn = Authenticator.isAuthenticated();

  const goTo = (path: string) => {
    history.push(path);
  };

  const goToRoot = () => {
    history.push("/");
  };

  const goToAutoTagging = () => {
    history.push("/autotagging");
  };

  const goToConceptManagement = () => {
    history.push("/concept-management");
  };

  const getInitials = (userName: string) => {
    let initials: string = "";
    for (let i = 0; i < userName.length; i++) {
      if (i === 0) initials += userName[i];
      if (initials.length > 1) break;
      if (userName[i] === "@" && userName[1]) {
        initials += userName[1];
      }
      if (userName[i - 1] === ".") initials += userName[i];
    }
    return initials.toUpperCase();
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenSettings = () => {
    setShowSettings(true);
    handleCloseMenu();
  };

  if (!isLoggedIn) {
    setIsAuthorized(false);
  }

  useEffect(() => {
    if (!isAuthorized) {
      Authenticator.signout(() => goTo("/logout"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  useEffect(() => {
    setInitials(getInitials(Authenticator.getUsername() || ""));
  }, []);

  return (
    <Navbar id="header" style={{ zIndex: 7 }}>
      <BurgerMenu menuOptions={menuOptions} />
      <Breadcrumb>
        <Breadcrumb.Item onClick={goToRoot} active={location.pathname === "/"}>
          Start
        </Breadcrumb.Item>

        {location.pathname.indexOf("/autotagging") !== -1 && (
          <Breadcrumb.Item
            onClick={goToAutoTagging}
            active={
              location.pathname === "/autotagging" ||
              location.pathname === "/autotagging/"
            }
            style={{ color: "#fff" }}
          >
            Auto-tagging
          </Breadcrumb.Item>
        )}
        {location.pathname.indexOf("/autotagging/tag-quality-assurance") !==
          -1 && (
          <Breadcrumb.Item
            onClick={() => goTo("/autotagging/tag-quality-assurance")}
            active={
              location.pathname === "/autotagging/tag-quality-assurance" ||
              location.pathname === "/autotagging/tag-quality-assurance/"
            }
          >
            Tag Quality Assurance
          </Breadcrumb.Item>
        )}

        {location.pathname.indexOf("/auto-tagging-quality-evaluator") !==
          -1 && (
          <Breadcrumb.Item
            onClick={() => goTo("/auto-tagging-quality-evaluator")}
            active={
              location.pathname.indexOf("/auto-tagging-quality-evaluator") !== -1
            }
          >
            Auto-tagging Quality Evaluator
          </Breadcrumb.Item>
        )}

        {location.pathname.indexOf("/concept-management") !== -1 && (
          <Breadcrumb.Item
            onClick={goToConceptManagement}
            active={
              location.pathname === "/concept-management" ||
              location.pathname === "/concept-management/"
            }
          >
            Concept Management
          </Breadcrumb.Item>
        )}

        {location.pathname.indexOf("/concept-management/suggestions") !==
          -1 && (
          <Breadcrumb.Item
            onClick={() => goTo("/concept-management/suggestions")}
            active={
              location.pathname.indexOf("/concept-management/suggestions") !==
              -1
            }
          >
            Concept Suggestions
          </Breadcrumb.Item>
        )}

        {location.pathname.indexOf("/concept-management/resources") !== -1 && (
          <Breadcrumb.Item
            onClick={() => goTo("/concept-management/resources")}
            active={
              location.pathname.indexOf("/concept-management/resources") !== -1
            }
          >
            Resource Management
          </Breadcrumb.Item>
        )}

        {location.pathname.indexOf("/concept-management/missing-data") !==
          -1 && (
          <Breadcrumb.Item
            onClick={() => goTo("/concept-management/missing-data")}
            active={
              location.pathname.indexOf("/concept-management/missing-data") !==
              -1
            }
          >
            Missing Data Detector
          </Breadcrumb.Item>
        )}

        {location.pathname.indexOf(
          "/concept-management/custom-analysis-rules"
        ) !== -1 && (
          <Breadcrumb.Item
            onClick={() => goTo("/concept-management/custom-analysis-rules")}
            active={
              location.pathname.indexOf(
                "/concept-management/custom-analysis-rules"
              ) !== -1
            }
          >
            Custom Analysis Rules
          </Breadcrumb.Item>
        )}

        {location.pathname.indexOf("/production-insights") !== -1 && (
          <Breadcrumb.Item
            onClick={() => goTo("/production-insights")}
            active={
              location.pathname === "/production-insights" ||
              location.pathname === "/production-insights/"
            }
          >
            Production Insights
          </Breadcrumb.Item>
        )}

        {location.pathname.indexOf(
          "/production-insights/production-overview"
        ) !== -1 && (
          <Breadcrumb.Item
            onClick={() => goTo("/production-insights/production-overview")}
            active={
              location.pathname.indexOf(
                "/production-insights/production-overview"
              ) !== -1
            }
          >
            Production Overview
          </Breadcrumb.Item>
        )}

        {location.pathname.indexOf(
          "/production-insights/concept-statistics"
        ) !== -1 && (
          <Breadcrumb.Item
            onClick={() => goTo("/production-insights/concept-statistics")}
            active={
              location.pathname.indexOf(
                "/production-insights/concept-statistics"
              ) !== -1
            }
          >
            Concept Statistics
          </Breadcrumb.Item>
        )}

        {location.pathname.indexOf("/imatrics-times") !== -1 && (
          <Breadcrumb.Item
            onClick={() => goTo("/imatrics-times")}
            active={
              location.pathname === "/imatrics-times" ||
              location.pathname === "/imatrics-times/"
            }
          >
            iMatrics Times
          </Breadcrumb.Item>
        )}

        {location.pathname.indexOf("/imatrics-times/topic-page-management") !==
          -1 && (
          <Breadcrumb.Item
            onClick={() => goTo("/imatrics-times/topic-page-management")}
            active={
              location.pathname.indexOf(
                "/imatrics-times/topic-page-management"
              ) !== -1
            }
          >
            Topic Page Management
          </Breadcrumb.Item>
        )}

        {location.pathname.indexOf("/api-documentation") !== -1 && (
          <Breadcrumb.Item
            onClick={() => goTo("/api-documentation")}
            active={
              location.pathname === "/api-documentation" ||
              location.pathname === "/api-documentation/"
            }
          >
            API Docs
          </Breadcrumb.Item>
        )}

        {location.pathname.indexOf("/admin-panel") !== -1 && (
          <Breadcrumb.Item
            onClick={() => goTo("/admin-panel")}
            active={
              location.pathname === "/admin-panel" ||
              location.pathname === "/admin-panel/"
            }
          >
            Admin Panel
          </Breadcrumb.Item>
        )}

        {location.pathname.indexOf("/admin-panel/users") !== -1 && (
          <Breadcrumb.Item
            onClick={() => goTo("/admin-panel/users")}
            active={location.pathname.indexOf("/admin-panel/users") !== -1}
          >
            Users
          </Breadcrumb.Item>
        )}
        {location.pathname.indexOf("/admin-panel/customer-settings") !== -1 && (
          <Breadcrumb.Item
            onClick={() => goTo("/admin-panel/customer-settings")}
            active={
              location.pathname.indexOf("/admin-panel/customer-settings") !== -1
            }
          >
            Customer Settings
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
      {isLoggedIn ? (
        <Fragment>
          <Navbar.Collapse className="justify-content-end">
            <IconButton size="small" onClick={handleOpenMenu}>
              <Avatar
                style={{
                  width: "32px",
                  height: "32px",
                  float: "right",
                  fontSize: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  color: "#0b2545",
                  backgroundColor: "#e8eef2",
                }}
              >
                {initials}
              </Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleCloseMenu}
            >
              <List
                dense
                subheader={
                  <MenuItem dense disabled divider style={{ opacity: 1 }}>
                    <ListItemText inset>
                      {Authenticator.getUsername()}
                    </ListItemText>
                  </MenuItem>
                }
              >
                <MenuItem disabled onClick={handleOpenSettings}>
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="button" style={{ fontWeight: 700 }}>
                      My Account
                    </Typography>
                  </ListItemText>
                </MenuItem>

                <MenuItem onClick={handleOpenSettings}>
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="button" style={{ fontWeight: 700 }}>
                      Preferences
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <Divider />

                <Link to="/logout">
                  <MenuItem>
                    <ListItemIcon>
                      <ExitToApp />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="button" style={{ fontWeight: 700 }}>
                        Log out
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                </Link>
              </List>
            </Menu>
          </Navbar.Collapse>
        </Fragment>
      ) : (
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text></Navbar.Text>
        </Navbar.Collapse>
      )}
    </Navbar>
  );
};

export default BreadCrumbs;
