import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Alert,
  Grid,
} from "@mui/material";
import {
  Description,
  Grain,
  Person,
  AccountBalance,
  LocationOn,
  EventNote,
  GridView,
  ArrowUpward,
  ArrowDownward,
  HorizontalRule,
} from "@mui/icons-material";

import GET_TOP_CONCEPTS from "../../queries/GET_TOP_CONCEPTS";

const positiveStyle = { color: "#56E39F" };
const negativeStyle = { color: "#EF6F6C" };
const neutralStyle = { color: "#586CBF" };

interface IProps {
  endpoint: any;
  type: string;
  startDate: string;
  endDate: string;
  channel: string;
  excludeAuthors: string[];
  addToConceptList: any;
  renderTypes: string[];
}

const sortOptions: string[] = [
  "usage desc",
  "usage asc",
  "change desc",
  "change asc",
];

const TopListConcept = (props: IProps) => {
  const {
    endpoint,
    type,
    startDate,
    endDate,
    channel,
    excludeAuthors,
    addToConceptList,
    renderTypes,
  } = props;

  const [concepts, setConcepts] = useState<any>(null);
  const [sortBy, setSortBy] = useState<string>(sortOptions[0]);

  const [getTopConcepts, { loading, error }] = useLazyQuery(GET_TOP_CONCEPTS, {
    fetchPolicy: "cache-and-network",
    variables: {
      query: {
        type: type,
        startDate: startDate,
        endDate: endDate,
        size: 20,
        channels: channel ? [channel] : [],
        excludeAuthors: excludeAuthors,
      },
    },
    onCompleted: (data) => {
      setConcepts(data?.getTopConcepts ?? []);
      const fetchedConcepts = data?.getTopConcepts
        ? [...data.getTopConcepts]
        : [];

      if (
        (type === "topic" && renderTypes.includes(type)) ||
        (type === "category" &&
          renderTypes.includes(type) &&
          !renderTypes.includes("topic")) ||
        (type === "entity" &&
          renderTypes.includes(type) &&
          !renderTypes.includes("topic") &&
          !renderTypes.includes("category"))
      ) {
        const newlyUsed = fetchedConcepts
          .filter((topic: any) => topic.usagePrevPeriod < 1)
          .slice(0, 5);
        const mostChanged = fetchedConcepts
          .filter((c) => c.usagePrevPeriod > 0)
          .sort((a: any, b: any) =>
            getLastPeriodChange(a.usage, a.usagePrevPeriod, true) >
            getLastPeriodChange(b.usage, b.usagePrevPeriod, true)
              ? -1
              : 1
          )
          .slice(0, 5);
        addToConceptList([...newlyUsed, ...mostChanged].slice(0, 5));
      }
    },
    onError: (err) => {
      setConcepts([]);
    },
  });

  useEffect(() => {
    setConcepts([]);
    getTopConcepts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, channel, startDate, endDate, excludeAuthors]);

  useEffect(() => {
    if (!concepts || concepts.length < 1) return;
    const sortedConcepts = [...concepts];
    if (sortBy === "usage asc") {
      sortedConcepts.sort((a: any, b: any) => a.usage - b.usage);
    } else if (sortBy === "usage desc") {
      sortedConcepts.sort((a: any, b: any) => b.usage - a.usage);
    } else if (sortBy === "change asc") {
      sortedConcepts.sort((a: any, b: any) =>
        getLastPeriodChange(a.usage, a.usagePrevPeriod) >
        getLastPeriodChange(b.usage, b.usagePrevPeriod)
          ? 1
          : -1
      );
    } else if (sortBy === "change desc") {
      sortedConcepts.sort((a: any, b: any) =>
        getLastPeriodChange(a.usage, a.usagePrevPeriod) >
        getLastPeriodChange(b.usage, b.usagePrevPeriod)
          ? -1
          : 1
      );
    }
    setConcepts(sortedConcepts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const getLastPeriodChange = (
    current: number,
    past: number,
    abs: boolean = false
  ) => {
    if (abs) return Math.abs(Math.round((current / past - 1) * 100));
    return Math.round((current / past - 1) * 100);
  };

  const LastPeriodChangeComponent = ({ current, past }: any) => {
    if (isNaN(getLastPeriodChange(current, past))) {
      return (
        <span style={positiveStyle}>
          new
          <ArrowUpward fontSize="small" sx={{ ml: 0.5 }} />
        </span>
      );
    }
    return Math.sign(getLastPeriodChange(current, past)) === -1 ? (
      <span style={negativeStyle}>
        {getLastPeriodChange(current, past) + "%"}
        <ArrowDownward fontSize="small" sx={{ ml: 0.5 }} />
      </span>
    ) : Math.sign(getLastPeriodChange(current, past)) === 1 ? (
      <span style={positiveStyle}>
        {isFinite(getLastPeriodChange(current, past))
          ? getLastPeriodChange(current, past) + "%"
          : "new"}

        <ArrowUpward fontSize="small" sx={{ ml: 0.5 }} />
      </span>
    ) : (
      <span style={neutralStyle}>
        {getLastPeriodChange(current, past) + "%"}
        <HorizontalRule fontSize="small" sx={{ ml: 0.5 }} />
      </span>
    );
  };

  return (
    <Box sx={{ overflow: "hidden", height: "100%", width: "100%" }}>
      <Box
        sx={{
          px: 2,
          py: 0,
          borderBottom: "1px solid #e0e0e0",
          bgcolor: "rgba(0, 0, 0, 0.04)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="overline" sx={{ fontWeight: "bold" }}>
          Top{" "}
          {type === "category"
            ? "categories"
            : type === "topic"
            ? "topics"
            : "entities"}
        </Typography>
        <Typography
          variant="overline"
          onClick={() => {
            const currentIndex = sortOptions.indexOf(sortBy);
            setSortBy(
              currentIndex === sortOptions.length - 1
                ? sortOptions[0]
                : sortOptions[currentIndex + 1]
            );
          }}
          sx={{ cursor: "pointer" }}
        >
          {sortBy}
        </Typography>
      </Box>
      <LinearProgress sx={{ visibility: !loading ? "hidden" : "visible" }} />
      <List
        dense
        disablePadding
        sx={{
          minHeight: "320px",
          height: "30vh",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        {error ? (
          <Alert severity="error" sx={{ m: 2 }}>
            Failed to fetch data.
          </Alert>
        ) : (
          <>
            {!loading && concepts?.length === 0 ? (
              <Alert variant="filled" severity="warning" sx={{ m: 2 }}>
                {`No ${type} data found.`}
              </Alert>
            ) : (
              concepts?.map((concept: any) => (
                <ListItem
                  key={concept.uuid}
                  button
                  onClick={() => {
                    addToConceptList(concept);
                  }}
                >
                  <ListItemText
                    primary={
                      <Box>
                        <Grid
                          container
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            item
                            xs={8}
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {concept.type.includes("category") ? (
                              <Description
                                fontSize="small"
                                sx={{ position: "relative" }}
                              />
                            ) : concept.type.includes("topic") ? (
                              <Grain
                                fontSize="small"
                                sx={{ position: "relative" }}
                              />
                            ) : concept.type.includes("person") ? (
                              <Person
                                fontSize="small"
                                sx={{ position: "relative" }}
                              />
                            ) : concept.type.includes("place") ? (
                              <LocationOn
                                fontSize="small"
                                sx={{ position: "relative" }}
                              />
                            ) : concept.type.includes("organisation") ? (
                              <AccountBalance
                                fontSize="small"
                                sx={{ position: "relative" }}
                              />
                            ) : concept.type.includes("event") ? (
                              <EventNote
                                fontSize="small"
                                sx={{ position: "relative" }}
                              />
                            ) : concept.type.includes("object") ? (
                              <GridView
                                fontSize="small"
                                sx={{ position: "relative" }}
                              />
                            ) : null}
                            <b style={{ padding: "0 4px" }}>{concept.title}</b>
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginLeft: "auto",
                              width: "90px",
                              color: "#999",
                              textAlign: "right",
                            }}
                          >
                            <b style={{ marginRight: "4px" }}>
                              {concept.usage}
                            </b>
                            <LastPeriodChangeComponent
                              current={concept.usage}
                              past={concept.usagePrevPeriod}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    }
                  />
                </ListItem>
              ))
            )}
          </>
        )}
      </List>
    </Box>
  );
};

export default TopListConcept;
